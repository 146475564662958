/*eslint-disable*/
import React, {
    useCallback,
    useEffect,
    useRef,
    useState,
    useMemo,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { IntlProvider } from "react-intl";

import {
    FiChevronLeft,
    FiChevronRight,
    FiPlus,
    FiRefreshCcw,
    FiBell,
} from "react-icons/fi";

import FastAPIClient from "../../client";
import fast_api_config from "../../config";

import CalendarModule from "../../components/Calendar";
import ToggleButton from "../../components/Buttons/ToggleButton";
import Loader from "../../components/Loader";
import OpenMapsButton from "../../components/Buttons/OpenMapsButton";
import DraggableMeetingList from "../../components/LayoutComponents/DraggableMeetingList";
import RoutingMap from "../../components/MapComponents/RoutingMap";
import CreateMeetingModal from "../../components/ModalComponents/CreateMeetingModal";
import { useOfflineMeetings } from "../../components/OfflineContext";

import messages_en from "./translations/en.json";
import messages_pt from "./translations/pt.json";
import messages_fr from "./translations/fr.json";

import update from "immutability-helper";

import "./style.css";

// Instantiate the FastAPI client
const client = new FastAPIClient(fast_api_config);

// Messages for localization
const messages = {
    en: messages_en,
    pt: messages_pt,
    fr: messages_fr,
};

const formatModalDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0");
    const day = String(d.getDate()).padStart(2, "0");
    const hours = String(d.getHours()).padStart(2, "0");
    const minutes = String(d.getMinutes()).padStart(2, "0");
    return `${year}-${month}-${day}T${hours}:${minutes}`;
};

const Routing = () => {
    const [viewMode, setViewMode] = useState("daily"); // new state for switching views
    const location = useLocation(); // Hook to get the current URL
    const navigate = useNavigate(); // Hook to update the URL
    const { offlineMeetings, offlineDeleteMeeting, offlineUpdateMeeting } =
        useOfflineMeetings(); // Destructure the offlineMeetings state from the context
    const queryParams = new URLSearchParams(location.search);

    const [meetings, setMeetings] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [original_meetings, setOriginalMeetings] = useState([]);
    const [selectedDraggable, setSelectedDraggable] = useState("");
    const [currentDate, setCurrentDate] = useState(new Date());
    const [filteredMeetings, setFilteredMeetings] = useState([]); // State for filtered meetings
    const now = new Date();
    const initialStartDate = formatModalDate(now); // Current date and time
    const initialEndDate = formatModalDate(
        new Date(now.getTime() + 30 * 60000)
    ); // Current date and time plus 30 minutes

    // Function to format date for display
    const formatDate = (date) => {
        return date.toLocaleDateString("fr-FR", {
            year: "numeric",
            month: "long",
            day: "numeric",
        });
    };
    const user = JSON.parse(localStorage.getItem("user"));

    // Handle changing date by one day
    const handleNextDay = () => {
        setCurrentDate((prevDate) => {
            const newDate = new Date(prevDate); // Create a new Date object
            newDate.setDate(newDate.getDate() + 1); // Increment the date by one day
            return newDate; // Return the new date
        });
    };

    // Handle changing date by one day
    const handlePreviousDay = () => {
        setCurrentDate((prevDate) => {
            const newDate = new Date(prevDate); // Create a new Date object
            newDate.setDate(newDate.getDate() - 1); // Increment the date by one day
            return newDate; // Return the new date
        });
    };

    const [meetingTypes, setMeetingTypes] = useState([]);

    // Extract onTransformedMeetings method: Transform meetings data
    const onTransformedMeetings = (data) => {
        if (!data?.length) return [];
        return data.map((meeting) => ({
            client_id: meeting.client.client_id,
            client_name: meeting.client.client_name,
            meeting_id: meeting.meeting_id,
            location: {
                lat: meeting.client.lat,
                lng: meeting.client.long,
            },
            time: {
                start: new Date(meeting.start_date).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                }),
                end: new Date(meeting.end_date).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                }),
            },
            date: meeting.start_date.split(" ")[0],
            distance: "0 km", // Placeholder for distance
            duration: "1 min", // Placeholder for duration
            alert: meeting.client.alert,
            is_visited: meeting.is_visited,
            meeting_type_id: meeting.meeting_type_id,
            is_recurring: meeting.is_recurring,
            attendees: meeting.attendees,
            db_key_path: meeting.db_key_path || null, // db_key_path: The key of indexBD data
            is_organizer: meeting.is_organizer,
        }));
    };

    // total_original_meetings: original_meetings concat with offline meetings
    const total_original_meetings = useMemo(() => {
        console.log("HERE WE GOOOOO");
        let meetingsList = original_meetings;
        if (offlineMeetings.length) {
            meetingsList = original_meetings.concat(offlineMeetings);
        }
        if (offlineDeleteMeeting.length) {
            meetingsList = meetingsList.filter(
                (meeting) => !offlineDeleteMeeting.includes(meeting.meeting_id)
            );
        }
        if (offlineUpdateMeeting.length) {
            console.log("offlineUpdateMeeting", offlineUpdateMeeting);
            meetingsList = meetingsList.map((meeting) => {
                const updateMeeting = offlineUpdateMeeting.find(
                    (updateMeeting) =>
                        updateMeeting.meeting_id === meeting.meeting_id
                );
                if (updateMeeting) {
                    console.log(updateMeeting);
                    return updateMeeting;
                }
                return meeting;
            });
        }
        return meetingsList.sort(
            (a, b) => new Date(a.start_date) - new Date(b.start_date)
        ); // Sort by start date
    }, [
        offlineMeetings,
        offlineDeleteMeeting,
        offlineUpdateMeeting,
        original_meetings,
    ]);

    const getMeetings = async () => {
        try {
            const user = JSON.parse(localStorage.getItem("user"));
            const data = await client.getMeetings(user.user_id);

            setOriginalMeetings(data.data?.results);

            // Transform each meeting object to the desired structure
            // const transformedMeetings =
            //    client_id: meeting.client.client_id,
            //     client_name: meeting.client.client_name,
            //     meeting_id: meeting.meeting_id,
            //     location: {
            //         lat: meeting.client.lat,
            //         lng: meeting.client.long,
            //     },
            //     time: {
            //         start: new Date(meeting.start_date).toLocaleTimeString(
            //             [],
            //             { hour: "2-digit", minute: "2-digit" }
            //         ),
            //         end: new Date(meeting.end_date).toLocaleTimeString([], {
            //             hour: "2-digit",
            //             minute: "2-digit",
            //         }),
            //     },
            //     date: meeting.start_date.split(" ")[0],
            //     distance: "0 km", // Placeholder for distance
            //     duration: "1 min", // Placeholder for duration
            //     alert: meeting.client.alert,
            //     is_visited: meeting.is_visited,
            //     meeting_type_id: meeting.meeting_type_id,
            //     attendees: meeting.attendees
            //     })) || [];

            // Transform each meeting object to the desired structure
            const transformedMeetings = onTransformedMeetings(
                data.data?.results
            );
            setMeetings(transformedMeetings);
            filterMeetingsByDate(new Date()); // Filter meetings for today's date initially
        } catch (error) {
            console.error("Error fetching meetings:", error);
        } finally {
            setRefreshing(false);
        }
    };

    const filterMeetingsByDate = (date) => {
        const selectedDateString = date.toISOString().split("T")[0]; // Get YYYY-MM-DD format
        // const filtered = meetings.filter((meeting) => {
        //     // Extract the date portion from meeting.date
        //     const meetingDateString = meeting.date.split("T")[0]; // Get YYYY-MM-DD format from meeting.date

        //     // Compare only the date parts
        //     return meetingDateString === selectedDateString;
        // });

        let totalMeetings = [
            ...meetings,
            ...onTransformedMeetings(offlineMeetings),
        ];
        if (offlineDeleteMeeting.length) {
            totalMeetings = totalMeetings.filter(
                (meeting) => !offlineDeleteMeeting.includes(meeting.meeting_id)
            );
        }
        if (offlineUpdateMeeting.length) {
            const formatOfflineUpdateMeeting =
                onTransformedMeetings(offlineUpdateMeeting);
            totalMeetings = totalMeetings.map((meeting) => {
                const updatedMeeting = formatOfflineUpdateMeeting.find(
                    (updatedMeeting) =>
                        updatedMeeting.meeting_id === meeting.meeting_id
                );
                if (updatedMeeting) {
                    return { ...meeting, ...updatedMeeting };
                }
                return meeting;
            });
        }
        const filtered = totalMeetings.filter((meeting) => {
            // Extract the date portion from meeting.date
            const meetingDateString = meeting?.date?.split("T")[0]; // Get YYYY-MM-DD format from meeting.date
            // Compare only the date parts
            return meetingDateString === selectedDateString;
        });

        // Sort by the date field
        const sortedResults = filtered.sort((a, b) => {
            return new Date(a.date) - new Date(b.date);
        });

        setFilteredMeetings(sortedResults);
        // setFilteredMeetings(filtered);
        // if (filtered.length > 0) {
        //     setRefreshing(false); // Immediately stop refreshing if condition is met
        // }
    };

    // Inside useEffect or wherever appropriate, call the filter function
    useEffect(() => {
        filterMeetingsByDate(currentDate); // Call the filter whenever selectedDate changes
    }, [
        currentDate,
        meetings,
        isEditing,
        offlineMeetings,
        offlineDeleteMeeting,
        offlineUpdateMeeting,
    ]);

    // TEMP FIX but won't work on offline mode, need to find a way to update meetings differently
    useEffect(() => {
        // After the offline data is cleared, refresh the meeting UI on the departure page
        getMeetings();
        fetchMeetingTypes();
    }, [viewMode]);

    // useEffect(() => {
    //     // After the offline data is cleared, refresh the meeting UI on the departure page
    //     console.log("meeting_types", meetingTypes);
    // }, [meetingTypes]);

    const handleViewToggle = () => {
        const newViewMode = viewMode === "daily" ? "weekly" : "daily";
        setViewMode(newViewMode);
        navigate(`?view=${newViewMode}`); // Update URL with the new view mode
    };

    const [refreshing, setRefreshing] = useState(true);
    const [locale, setLocale] = useState();

    useEffect(() => {
        setLocale(localStorage.getItem("language"));
        // Read viewMode from URL when component mounts
        const currentView = queryParams.get("view");
        if (currentView && currentView !== viewMode) {
            setViewMode(currentView);
        }
    }, [location]);

    const moveCard = useCallback((dragIndex, hoverIndex) => {
        setFilteredMeetings((prevCards) =>
            update(prevCards, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, prevCards[dragIndex]],
                ],
            })
        );
    }, []);

    const formatEvents = (list) => {
        return list.map((item) => ({
            title: item.summary,
            meeting_id: item.meeting_id,
            start: item.start.dateTime || item.start.date,
            end: item.end.dateTime || item.end.date,
        }));
    };

    const fetchMeetingTypes = () => {
        client.getMeetingTypes().then((data) => {
            setMeetingTypes(data?.data.results || []);
            // console.log("meeting types", data?.data.results);
        });
    };

    const [newEventData, setNewEventData] = useState({
        title: "",
        start: initialStartDate,
        end: initialEndDate,
        attendees: [],
    });

    const [reminders, setReminder] = useState([]);

    useEffect(() => {
        const handleOnlineStatus = () => {
            if (navigator.onLine) {
                fetchReminder();
            } else {
            }
        };

        // Run once on mount
        handleOnlineStatus();

        // Listen for network status changes
        window.addEventListener("online", handleOnlineStatus);
        window.addEventListener("offline", handleOnlineStatus);

        return () => {
            window.removeEventListener("online", handleOnlineStatus);
            window.removeEventListener("offline", handleOnlineStatus);
        };
    }, [user]);

    const fetchReminder = () => {
        if (user.user_id != null) {
            client.getReminder(null, user.user_id).then((data) => {
                const sortedResults = data.results;

                // Get current date in YYYY-MM-DD format for comparison
                const currentDate = new Date().toISOString().split("T")[0]; // e.g., "2025-01-25"

                // Filter reminders where the date is today's date
                const filteredReminders = sortedResults.filter((reminder) => {
                    const reminderDate = new Date(reminder.date)
                        .toISOString()
                        .split("T")[0];
                    return reminderDate === currentDate;
                });

                setReminder(filteredReminders);
            });
        } else {
            console.log("user_id is null or undefined, skipping API call.");
        }
    };

    const openModal = () => {
        setNewEventData({
            ...newEventData,
            start: initialStartDate,
            end: initialEndDate,
        });
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const deleteMeeting = (filterFunction) => {
        setFilteredMeetings((prevMeetings) => filterFunction(prevMeetings));
    };

    const handleDeleteMeeting = (meetingId) => {
        deleteMeeting(
            (newEvents) =>
                newEvents.filter((event) => event.meeting_id !== meetingId) // Filter out the meeting with the passed meeting_id
        );
    };

    const [successMessage, setSuccessMessage] = useState(""); // Track success message
    useEffect(() => {
        if (successMessage) {
            const timer = setTimeout(() => setSuccessMessage(""), 3000); // Hide message after 3 seconds
            return () => clearTimeout(timer); // Cleanup on unmount
        }
    }, [successMessage]);

    const [errorMessage, setErrorMessage] = useState(""); // Track success message
    useEffect(() => {
        if (errorMessage) {
            const timer = setTimeout(() => setErrorMessage(""), 3000); // Hide message after 3 seconds
            return () => clearTimeout(timer); // Cleanup on unmount
        }
    }, [errorMessage]);

    if (refreshing) {
        return <Loader />; // Display Loader while data is being fetched
    }

    return (
        <DndProvider backend={HTML5Backend}>
            <IntlProvider locale={locale} messages={messages[locale]}>
                <section className="w-full h-full flex flex-row overflow-y-hidden">
                    {viewMode === "daily" ? (
                        <div className="flex md:flex-row md:items-center mt-10 md:mt-0 md:h-full h-full w-full md:w-2/5 xl:w-1/3">
                            {/* Toggle Buttons to switch between views */}

                            {/* Conditionally render content based on viewMode */}

                            <div className="flex flex-col items-center w-full h-5/6">
                                {/* Daily View Content */}
                                <div className="md:w-4/5 h-full">
                                    <div className="md:flex md:justify-end md:mb-20 hidden">
                                        <ToggleButton
                                            isWeekly={viewMode === "weekly"}
                                            onToggle={handleViewToggle}
                                        />
                                    </div>
                                    <div className="">
                                        <div className="flex flex-row mb-10 items-center justify-center">
                                            <button
                                                className="w-1/4 text-xs flex flex-col items-center text-lg"
                                                onClick={handlePreviousDay}
                                            >
                                                <FiChevronLeft />
                                            </button>
                                            <div className="w-1/2 text-lg">
                                                {formatDate(currentDate)}{" "}
                                                {/* Display current date */}
                                            </div>
                                            <button
                                                className="w-1/4 text-xs flex flex-col items-center text-lg"
                                                onClick={handleNextDay}
                                            >
                                                <FiChevronRight />
                                            </button>
                                        </div>
                                    </div>

                                    <div className="overflow-y-auto h-[85%] md:h-96 custom-scrollbar">
                                        <div className="flex flex-col items-center w-full pl-5 pr-5 text-base">
                                            {reminders.map((reminder) => (
                                                <li
                                                    key={reminder.id}
                                                    className="flex flex-row rounded-md border border-rose-500 items-center w-full px-2 mb-1"
                                                >
                                                    <div className="flex text-sm text-rose-500 w-1/12 ">
                                                        <FiBell />
                                                    </div>
                                                    <div className="flex flex-col text-rose-500 text-xxs md:text-sm w-5/6 text-left">
                                                        <div>
                                                            <strong className="mr-2 text-xxs font-semibold">
                                                                {
                                                                    reminder
                                                                        .client
                                                                        .client_name
                                                                }
                                                            </strong>
                                                        </div>
                                                        <div className="mr-2 text-xs">
                                                            {reminder.title}:{" "}
                                                            {reminder.note}
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}
                                        </div>{" "}
                                        {/* Set a fixed height and allow overflow */}
                                        {filteredMeetings.length === 0 ? (
                                            <div className="text-center py-10">
                                                <h2 className="text-xl font-semibold text-gray-700 mb-2">
                                                    Il semble qu'il n'y ait pas
                                                    de réunions aujourd'hui.
                                                </h2>
                                                <p className="text-gray-500">
                                                    Profitez de votre journée
                                                    pour planifier ou vous
                                                    reposer. 😊
                                                </p>
                                            </div>
                                        ) : (
                                            filteredMeetings.map((card, i) => (
                                                <DraggableMeetingList
                                                    key={card.id}
                                                    index={i}
                                                    id={card.client_id}
                                                    text={card.client_name}
                                                    is_recurring={
                                                        card.is_recurring
                                                    }
                                                    alert={card.alert}
                                                    time={card.time}
                                                    meeting_id={card.meeting_id}
                                                    meeting_type={
                                                        card.meeting_type_id
                                                    }
                                                    distance={card.distance}
                                                    duration={card.duration}
                                                    is_visited={card.is_visited}
                                                    moveCard={moveCard}
                                                    isLastCard={
                                                        i ===
                                                        filteredMeetings.length -
                                                            1
                                                    }
                                                    handleDeleteMeeting={
                                                        handleDeleteMeeting
                                                    }
                                                    locale={locale}
                                                    selectedDraggable={
                                                        selectedDraggable
                                                    }
                                                    setSelectedDraggable={
                                                        setSelectedDraggable
                                                    }
                                                />
                                            ))
                                        )}
                                    </div>
                                </div>
                                <div className="h-full flex justify-center items-center w-full">
                                    <div
                                        className="bg-black text-xxl text-white rounded-full p-2 mt-2"
                                        onClick={openModal}
                                    >
                                        <FiPlus />
                                    </div>
                                </div>
                                {isModalOpen && user.user_id && (
                                    <CreateMeetingModal
                                        closeModal={closeModal}
                                        initialEventData={newEventData}
                                        locale={locale}
                                        isEditing={isEditing}
                                        setIsEditing={setIsEditing}
                                        user_id={user.user_id}
                                        user={user}
                                        setSuccessMessage={setSuccessMessage}
                                        setErrorMessage={setErrorMessage}
                                    />
                                )}
                                {/* <OpenMapsButton
                                    meetings={total_original_meetings}
                                    locale={locale}
                                /> */}
                            </div>
                        </div>
                    ) : (
                        // Weekly View: Only show the calendar
                        <div className="w-4/5 ml-40">
                            {total_original_meetings &&
                                meetingTypes.length > 0 && (
                                    <CalendarModule
                                        meetings={total_original_meetings}
                                        locale={locale}
                                        isEditing={isEditing}
                                        setIsEditing={setIsEditing}
                                        setOriginalMeetings={
                                            setOriginalMeetings
                                        }
                                        meetingTypes={meetingTypes}
                                    />
                                )}
                            <div className="flex flex-row justify-end">
                                <ul className="flex space-x-2 text-xs gap-2 p-2 text-left">
                                    {meetingTypes &&
                                        meetingTypes.map((meeting) => (
                                            <li
                                                key={meeting.meeting_type_id}
                                                className="grid grid-cols-[20px_1fr] items-center"
                                            >
                                                <div
                                                    className={`w-2 h-2 rounded-full ${meeting.color}`}
                                                    // style={{
                                                    //     backgroundColor:
                                                    //         meeting.color,
                                                    // }} // Apply hex color here
                                                />
                                                <div>{meeting.description}</div>
                                            </li>
                                        ))}
                                </ul>
                            </div>
                        </div>
                    )}

                    {viewMode === "daily" && meetings.length > 0 && (
                        <RoutingMap
                            cards={filteredMeetings}
                            selectedPoint={selectedDraggable}
                        />
                    )}
                    {successMessage && (
                        <div className="fixed bottom-0 left-0 right-0 bg-green-500 text-white text-center py-2 z-50 mx-auto w-1/5 rounded-t-lg">
                            {successMessage}
                        </div>
                    )}
                    {errorMessage && (
                        <div className="fixed bottom-0 left-0 right-0 bg-rose-500 text-white text-center py-2 z-50 mx-auto w-1/5 rounded-t-lg">
                            {errorMessage}
                        </div>
                    )}
                </section>
            </IntlProvider>
        </DndProvider>
    );
};

export default Routing;
