import React from "react"
import "./Throbber.css"

const Throbber = () => {
  return (
    <div className="lds-ring"><div></div><div></div><div></div><div></div></div>

  )
}

export default Throbber
