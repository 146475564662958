
import build_param from '../../build_params.json';
import { CACHE_RESPONSE_API,CACHE_RESPONSE_DATA, OFFINE_OPERATE_DATA } from './constants'
// get idb store name by api path
export function getDBResponsenName(apiPath) {
  if (!apiPath) return null;
  if(!CACHE_RESPONSE_DATA[apiPath]) return null
  return CACHE_RESPONSE_DATA[apiPath].storeName || null;
}

// get indexDB store name by api path
export function getDBOperateName({ path, method }) {
  if (!path || !method) return null;
  const apiMethodPair = { path, method };
  const matchingApiKey = JSON.stringify(apiMethodPair);
  return OFFINE_OPERATE_DATA[matchingApiKey] || null;
}

// get api lists by CACHE_RESPONSE_API
export function getValuesList(obj = CACHE_RESPONSE_API) {
  return Object.values(obj);
}

// A db database name is generated based on the current client id
export const getDbName = (name) => {
  return `${build_param.client_id}_${name}`;
};