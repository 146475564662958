import React from "react";

import Collapsible from "../../CollapseComponents/Collapsible";

const List = ({
    recommendation,
    getRecommendations,
    clientInfo,
    client_id,
}) => {
    console.log("recommendation", recommendation);
    return (
        recommendation && (
            <>
                <li className="text-sm p-2 mb-1 bg-white w-full">
                    <Collapsible
                        item={recommendation}
                        text={recommendation.description}
                        read={recommendation.read}
                        task_choices={recommendation.task_choice}
                        getRecommendations={getRecommendations}
                        client_id={client_id}
                        clientInfo={clientInfo}
                    />
                </li>
            </>
        )
    );
};

export default List;
