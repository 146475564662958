// React and related hooks
import React, { useState, useEffect, useMemo } from "react";

// Routing
import { useNavigate } from "react-router-dom";

// Styles
import "./style.css";

// Utilities and configurations
import FastAPIClient from "../../client";
import config from "../../config";
import * as moment from "moment";

// Localization
import { FormattedMessage, IntlProvider } from "react-intl";
import messages_en from "./translations/en.json";
import messages_fr from "./translations/fr.json";

// Components
import Loader from "../../components/Loader";
import SmallWidgetButton from "../../components/LayoutComponents/HomeWidgetButton";
import LargeWidgetButton from "../../components/LayoutComponents/LargeWidgetButton";
import ClientMapCard from "../../components/LayoutComponents/ClientMapCard";
import { useOfflineMeetings } from "../../components/OfflineContext";
import ClientSearch from "../../components/Search/ClientSearch";

import { FiBell } from "react-icons/fi"; // Import the bell icon

// Icons
import {
    FiUsers,
    FiCalendar,
    FiBarChart2,
    FiUserPlus,
    FiShoppingBag,
} from "react-icons/fi";

// import { CgPerformance } from "react-icons/cg";
import { PiGraduationCapBold } from "react-icons/pi";

// Authentication
import { useAuth0 } from "@auth0/auth0-react";

// Language messages
const messages = {
    en: messages_en,
    fr: messages_fr,
};

// Client instance
const client = new FastAPIClient(config);

const useMediaQuery = (query) => {
    const [matches, setMatches] = useState(false);

    useEffect(() => {
        const media = window.matchMedia(query);
        setMatches(media.matches);

        const handler = (event) => setMatches(event.matches);
        media.addListener(handler);

        return () => media.removeListener(handler);
    }, [query]);

    return matches;
};

const Home = () => {
    const [time, setTime] = useState(false);
    const [username, setUsername] = useState(false);
    // const [recommendationNotification, setRecommendationNotification] =
    //     useState(false);
    const [refreshing, setRefreshing] = useState(true);

    const [clients, setClients] = useState([]);

    const [reminders, setReminder] = useState([]);

    const [locale, setLocale] = useState();

    const { getAccessTokenSilently } = useAuth0();
    const {
        offlineMeetings,
        offlineDeleteMeeting,
        offlineRecommendations,
        offlineUpdateMeeting,
    } = useOfflineMeetings();

    useEffect(() => {
        // Set the current time using the moment library
        setTime(moment().format("HH:mm"));
    }, []);

    const recommendationNotification = useMemo(() => {
        if (!clients.length) return [];
        if (offlineRecommendations.length) {
            const list = clients.filter(
                (item) =>
                    item.alert === true &&
                    !offlineRecommendations.some(
                        (recommendation) =>
                            recommendation.client_id === item.client_id
                    )
            );
            return list;
        }
        return clients.filter((item) => item.alert == true);
    }, [offlineRecommendations, clients]);

    // Function to fetch user data and save it in localStorage
    const fetchUserData = async () => {
        try {
            const data = await client.fetchUser();

            // Save user data in localStorage
            localStorage.setItem("user", JSON.stringify(data));
            localStorage.setItem("language", data.language);
            setLocale(localStorage.getItem("language"));
        } catch (error) {
            console.error("Error fetching user data:", error);
        }
    };

    useEffect(() => {
        const handleOnlineStatus = () => {
            if (navigator.onLine) {
                fetchUserClientsOnline();
                fetchReminder();
            } else {
                fetchUserClientsOffline();
            }
        };

        // Run once on mount
        handleOnlineStatus();

        // Listen for network status changes
        window.addEventListener("online", handleOnlineStatus);
        window.addEventListener("offline", handleOnlineStatus);

        return () => {
            window.removeEventListener("online", handleOnlineStatus);
            window.removeEventListener("offline", handleOnlineStatus);
        };
    }, [username]);

    const fetchUserClientsOffline = () => {
        if (username.user_id != null) {
            client.getUserClients(username.user_id, null).then((data) => {
                const sortedResults = data.sort((a, b) => {
                    // Move objects with 'alert' as true to the beginning
                    return b.alert - a.alert; // b.alert - a.alert will move true values to the top
                });
                setClients(sortedResults);
            });
        } else {
            console.log("user_id is null or undefined, skipping API call.");
        }
    };

    const fetchUserClientsOnline = () => {
        if (username.user_id != null) {
            client.getUserClientsOnline(username.user_id, null).then((data) => {
                const sortedResults = data.sort((a, b) => {
                    // Move objects with 'alert' as true to the beginning
                    return b.alert - a.alert; // b.alert - a.alert will move true values to the top
                });
                setClients(sortedResults);
            });
        } else {
            console.log("user_id is null or undefined, skipping API call.");
        }
    };

    const fetchReminder = () => {
        if (username.user_id != null) {
            client.getReminder(null, username.user_id).then((data) => {
                const sortedResults = data.results;

                // Get current date in YYYY-MM-DD format for comparison
                const currentDate = new Date().toISOString().split("T")[0]; // e.g., "2025-01-25"

                // Filter reminders where the date is today's date
                const filteredReminders = sortedResults.filter((reminder) => {
                    const reminderDate = new Date(reminder.date)
                        .toISOString()
                        .split("T")[0];
                    return reminderDate === currentDate;
                });

                setReminder(filteredReminders);
            });
        } else {
            console.log("user_id is null or undefined, skipping API call.");
        }
    };

    useEffect(() => {
        const fetchAccessToken = async () => {
            try {
                const accessToken = await getAccessTokenSilently({
                    authorizationParams: {
                        audience: "https://www.api.myway.technology",
                        scope: "read:appointments",
                    },
                });

                localStorage.setItem("token", accessToken);
                // You can set the current time using the moment library here if needed
            } catch (error) {
                console.error("Failed to fetch access token:", error);
            }
        };

        const tokenData = localStorage.getItem("token");

        // Check if tokenData is empty or null
        if (tokenData == null || tokenData == undefined) {
            fetchAccessToken();
        }
    }, []); // Empty dependency array ensures this effect runs only once on component mount

    useEffect(() => {
        const fetchData = async () => {
            await Promise.all([getUserName()]);

            if (!localStorage.getItem("language")) {
                await fetchUserData();
            } else {
                setLocale(localStorage.getItem("language"));
            }
        };

        fetchData();
    }, []);

    const getUserName = () => {
        client.fetchUser().then((data) => {
            // Set the username in the state
            console.log(data);
            setUsername(data);
        });
    };

    const nav = useNavigate();

    const [meetings, setMeetings] = useState([]);

    // Helper function to format date to "YYYY-MM-DDTHH:mm:ss"
    const formatDateTime = (date) => {
        return date.toISOString().slice(0, 19); // Format to "YYYY-MM-DDTHH:mm:ss"
    };

    // Set begin_time_frame to today at 00:00:00
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const begin_time_frame = formatDateTime(today);

    // Set end_time_frame to tomorrow at 00:00:00
    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    const end_time_frame = formatDateTime(tomorrow);

    //  format meetings data
    const onTransformedMeetings = (data) => {
        if (!data?.length) return [];
        return data.map((meeting) => ({
            client_id: meeting.client.client_id,
            client_name: meeting.client.client_name,
            meeting_id: meeting.meeting_id,
            meeting_type_id: meeting.meeting_type_id,
            is_visited: meeting.is_visited,
            location: {
                lat: meeting.client.lat,
                lng: meeting.client.long,
            },
            time: {
                start: new Date(meeting.start_date).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                }),
                end: new Date(meeting.end_date).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                }),
            },
            distance: "0 km", // Placeholder for distance
            duration: "1 min", // Placeholder for duration
            alert: meeting.client.alert,
        }));
    };

    // sort meetings by time
    const sortMeetingsByTime = (meetings) => {
        if (!meetings?.length) return [];
        return meetings.sort((a, b) => {
            const aStartTime = moment(a.time.start, "HH:mm");
            const bStartTime = moment(b.time.start, "HH:mm");

            if (aStartTime.isSame(bStartTime)) {
                const aEndTime = moment(a.time.end, "HH:mm");
                const bEndTime = moment(b.time.end, "HH:mm");
                return aEndTime.diff(bEndTime);
            }

            return aStartTime.diff(bStartTime);
        });
    };

    // Sort meetings by date
    const filterMeetingsByDate = (meetings) => {
        if (!meetings?.length) return [];
        return meetings.filter((meeting) => {
            const meetingStart = new Date(meeting.start_date);
            const meetingEnd = new Date(meeting.end_date);

            // Check if the meeting starts or ends within the time frame
            return (
                (meetingStart >= new Date(begin_time_frame) &&
                    meetingStart <= new Date(end_time_frame)) ||
                (meetingEnd >= new Date(begin_time_frame) &&
                    meetingEnd <= new Date(end_time_frame))
            );
        });
    };

    // get all meetings data：offline meetings + online meetings
    const totalMeetings = useMemo(() => {
        let totalList = [];
        if (offlineMeetings.length) {
            const filterMeetings = filterMeetingsByDate(offlineMeetings);
            if (meetings.length) {
                totalList = meetings.concat(filterMeetings);
            } else {
                totalList = filterMeetings;
            }
        } else {
            totalList = meetings;
        }
        if (offlineDeleteMeeting.length) {
            totalList = totalList.filter(
                (meeting) => !offlineDeleteMeeting.includes(meeting.meeting_id)
            );
        }
        if (offlineUpdateMeeting.length) {
            totalList = totalList.map((meeting) => {
                const formatUpdateMeetings =
                    filterMeetingsByDate(offlineUpdateMeeting);
                const updatedMeeting = formatUpdateMeetings.find(
                    (item) => item.meeting_id === meeting.meeting_id
                );
                return updatedMeeting ? updatedMeeting : meeting;
            });
        }
        return sortMeetingsByTime(onTransformedMeetings(totalList)); // sort by time and transform meeting data
    }, [offlineMeetings, offlineDeleteMeeting, offlineUpdateMeeting, meetings]);
    const getMeetings = async () => {
        if (username.user_id != null) {
            try {
                const data = await client.getMeetings(
                    username.user_id,
                    undefined,
                    begin_time_frame,
                    end_time_frame
                );

                setMeetings(data.data?.results);
            } catch (error) {
                console.error("Error fetching meetings:", error);
            } finally {
                setRefreshing(false);
            }
        } else {
            console.log("user_id is null or undefined, skipping API call.");
        }
    };
    // After the offline data is cleared, refresh the meeting UI on the departure page
    useEffect(() => {
        getMeetings();
    }, [username, offlineMeetings]);

    const isMdOrLarger = useMediaQuery("(min-width: 768px)");

    if (refreshing) {
        // Redirect to another page if user is not logged in
        return <Loader />;
    }

    return (
        <>
            <section className="h-full w-full">
                <IntlProvider locale={locale} messages={messages[locale]}>
                    {!username ||
                        (locale != "en" && locale != "fr" && (
                            <div>
                                <Loader />
                            </div>
                        ))}
                    {!refreshing &&
                        username &&
                        (locale == "en" || locale == "fr") && (
                            <div className="w-full rounded-3xl md:rounded-none flex fex-row items-center h-full top-1/2 ">
                                <div className="flex flex-col items-center w-full h-full">
                                    <div className="flex flex-col items-center justify-center rounded-3xl md:rounded-none mb-5 w-full">
                                        <div className="mt-10 md:mt-12 text-lg md:text-xl tracking-wider text-black">
                                            {time && (
                                                // Display the current time and username once they are available
                                                <>
                                                    <FormattedMessage
                                                        id="home.welcome"
                                                        values={{
                                                            time,
                                                            username:
                                                                username.first_name,
                                                        }}
                                                    />
                                                    <br />
                                                    <FormattedMessage
                                                        id="home.userName"
                                                        values={{
                                                            time,
                                                            username:
                                                                username.first_name,
                                                        }}
                                                    />
                                                    <div>
                                                        <span
                                                            style={{
                                                                display:
                                                                    "inline-block",
                                                            }}
                                                            className="rc-icon rc-behavior rc-brand3"
                                                        ></span>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                        <div className="flex flex-col md:flex-row mr-10 ml-10 md:mt-0 items-center max-w-full">
                                            <ClientSearch clients={clients} />
                                        </div>
                                    </div>

                                    <div className="flex flex-col items-center justify-center w-full md:mt-5 h-full md:h-full">
                                        <div className="grid grid-cols-2 gap-2 md:grid-cols-3 md:gap-4 w-5/6 md:w-4/5">
                                            <div className="col-span-1 row-span-1 md:row-span-2">
                                                {username.is_route_module &&
                                                    !isMdOrLarger && (
                                                        <SmallWidgetButton
                                                            text={
                                                                <FormattedMessage id="home.myDay" />
                                                            }
                                                            onClick={() =>
                                                                nav("/routing")
                                                            }
                                                            icon={
                                                                <FiCalendar />
                                                            }
                                                            notification={
                                                                reminders.length !==
                                                                    0 && (
                                                                    <FormattedMessage
                                                                        id="home.newRecos"
                                                                        values={{
                                                                            number: reminders.length,
                                                                        }}
                                                                    />
                                                                )
                                                            }
                                                        />
                                                    )}
                                                {username.is_route_module &&
                                                    isMdOrLarger && (
                                                        <LargeWidgetButton
                                                            text={
                                                                <FormattedMessage id="home.myDay" />
                                                            }
                                                            notification={
                                                                <FormattedMessage
                                                                    id="home.newVisits"
                                                                    values={{
                                                                        number: 3,
                                                                    }}
                                                                />
                                                            }
                                                            icon={
                                                                <FiCalendar />
                                                            }
                                                        >
                                                            <div className="flex flex-col items-center w-full pl-5 pr-5 text-base">
                                                                {reminders.map(
                                                                    (
                                                                        reminder
                                                                    ) => (
                                                                        <li
                                                                            key={
                                                                                reminder.id
                                                                            }
                                                                            className="flex flex-row rounded-md border border-rose-500 items-center w-full px-2 mb-1"
                                                                        >
                                                                            <div className="flex text-sm text-rose-500 w-1/12 ">
                                                                                <FiBell />
                                                                            </div>
                                                                            <div className="flex flex-col text-rose-500 text-xxs md:text-sm w-5/6 text-left">
                                                                                <div>
                                                                                    <strong className="mr-2 text-xxs font-semibold">
                                                                                        {
                                                                                            reminder
                                                                                                .client
                                                                                                .client_name
                                                                                        }
                                                                                    </strong>
                                                                                </div>
                                                                                <div className="mr-2 text-xs">
                                                                                    {
                                                                                        reminder.title
                                                                                    }

                                                                                    :{" "}
                                                                                    {
                                                                                        reminder.note
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                )}
                                                            </div>
                                                            {totalMeetings.length >
                                                                0 &&
                                                                totalMeetings.map(
                                                                    (
                                                                        card,
                                                                        i
                                                                    ) => (
                                                                        <ClientMapCard
                                                                            // key={
                                                                            //     card.id
                                                                            // }
                                                                            key={
                                                                                i
                                                                            }
                                                                            index={
                                                                                i
                                                                            }
                                                                            id={
                                                                                card.client_id
                                                                            }
                                                                            text={
                                                                                card.client_name
                                                                            }
                                                                            time={
                                                                                card.time
                                                                            }
                                                                            distance={
                                                                                card.distance
                                                                            }
                                                                            duration={
                                                                                card.duration
                                                                            }
                                                                            alert={
                                                                                card.alert
                                                                            }
                                                                            isLastCard={
                                                                                i ===
                                                                                totalMeetings.length -
                                                                                    1
                                                                            }
                                                                            locale={
                                                                                locale
                                                                            }
                                                                            is_visited={
                                                                                card.is_visited
                                                                            }
                                                                            meeting_id={
                                                                                card.meeting_id
                                                                            }
                                                                            meeting_type={
                                                                                card.meeting_type_id
                                                                            }
                                                                        />
                                                                    )
                                                                )}
                                                        </LargeWidgetButton>
                                                    )}
                                            </div>

                                            <SmallWidgetButton
                                                text={
                                                    <FormattedMessage id="home.Clients" />
                                                }
                                                notification={
                                                    recommendationNotification.length !==
                                                        0 && (
                                                        <FormattedMessage
                                                            id="home.newRecos"
                                                            values={{
                                                                number: recommendationNotification.length,
                                                            }}
                                                        />
                                                    )
                                                }
                                                onClick={() => nav("/clients")}
                                                icon={<FiUsers />}
                                            />

                                            {username.is_training_module && (
                                                <SmallWidgetButton
                                                    text={
                                                        <FormattedMessage id="home.Training" />
                                                    }
                                                    notification={
                                                        <FormattedMessage
                                                            id="home.newTask"
                                                            values={{
                                                                number: 1,
                                                            }}
                                                        />
                                                    }
                                                    onClick={() =>
                                                        nav("/training")
                                                    }
                                                    icon={
                                                        <PiGraduationCapBold />
                                                    }
                                                />
                                            )}
                                            {username.is_lead_module && (
                                                <SmallWidgetButton
                                                    text={
                                                        <FormattedMessage id="home.Leads" />
                                                    }
                                                    notification={
                                                        <FormattedMessage
                                                            id="home.newLeads"
                                                            values={{
                                                                number: 1,
                                                            }}
                                                        />
                                                    }
                                                    onClick={() =>
                                                        nav("/leads")
                                                    }
                                                    icon={<FiUserPlus />}
                                                />
                                            )}

                                            <SmallWidgetButton
                                                text={
                                                    <FormattedMessage id="home.Product" />
                                                }
                                                onClick={() => nav("/products")}
                                                icon={<FiShoppingBag />}
                                            />

                                            {username.user_role_id ===
                                                "R-03" && (
                                                <SmallWidgetButton
                                                    text="Tableaux de bord"
                                                    onClick={() =>
                                                        nav("/profile")
                                                    }
                                                    icon={<FiBarChart2 />}
                                                />
                                            )}

                                            {(username.user_role_id ===
                                                "R-01" ||
                                                username.user_role_id ===
                                                    "R-02") && (
                                                <SmallWidgetButton
                                                    text={
                                                        <FormattedMessage id="home.Management" />
                                                    }
                                                    onClick={() =>
                                                        nav(
                                                            "/management/dashboard"
                                                        )
                                                    }
                                                    icon={<FiBarChart2 />}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                </IntlProvider>
            </section>
        </>
    );
};

export default Home;
