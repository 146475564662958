import React from "react";
import { FiHome } from "react-icons/fi";

const ClientChatPreview = ({
    client,
    handleClientClick,
    notifications,
    selectedClient,
}) => {
    return (
        <li
            style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
            }}
            onClick={() => handleClientClick(client.client_id)}
            className={`${
                selectedClient === client.client_id ? "bg-gray-100" : "bg-white"
            } border-b border-gray-300 pr-5`}
        >
            <div className="flex items-center w-full h-full">
                <div
                    className={`${
                        selectedClient === client.client_id
                            ? "bg-black"
                            : "font-normal"
                    } rounded-full w-[3px] h-[80px] flex justify-center items-center`}
                />
                <div
                    className={`${
                        selectedClient === client.client_id
                            ? "bg-gray-300"
                            : "bg-gray-100"
                    } rounded-full w-[50px] h-[50px] flex justify-center items-center ml-2`}
                >
                    <FiHome color="white" />
                </div>
                <div className="ml-2 text-left flex-1">
                    <div
                        className={`text-base w-full ${
                            selectedClient === client.client_id
                                ? "font-bold"
                                : "font-normal"
                        }`}
                    >
                        {client.client_name}
                    </div>
                </div>
                <div className="ml-auto text-right h-full">
                    {notifications?.length > 0 &&
                        notifications
                            .filter(
                                (notif) => notif.client_id === client.client_id
                            )
                            .map((notif, index) => (
                                <span
                                    key={index}
                                    className="relative inline-block"
                                    style={{
                                        width: "20px",
                                        height: "20px",
                                        borderRadius: "50%",
                                        backgroundColor: notif.is_mentioned
                                            ? "red"
                                            : "black",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        color: "white",
                                        fontSize: "12px",
                                    }}
                                >
                                    {notif.is_mentioned ? "1" : ""}
                                </span>
                            ))}
                </div>
            </div>
        </li>
    );
};

export default ClientChatPreview;
