import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Make sure you import useNavigate
import { FormattedMessage } from "react-intl";

const ClientSearch = ({ clients }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredClients, setFilteredClients] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const nav = useNavigate(); // Use the navigate function from react-router-dom

    useEffect(() => {
        if (searchTerm === "") {
            setFilteredClients([]);
        } else {
            const filtered = clients.filter(
                (client) =>
                    client.client_name
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase()) ||
                    client.client_id.toString().includes(searchTerm) // Convert product_id to string before searching
            );
            setFilteredClients(filtered);
        }
    }, [searchTerm, clients]);

    const handleClientClick = (clientId) => {
        // Navigate to the client's page using their ID
        nav(`/client/${clientId}`);
        setShowSuggestions(false); // Hide suggestions on selection
    };

    return (
        <div className="relative flex flex-col md:flex-row mr-20 ml-20 md:mt-5 items-center w-full">
            <FormattedMessage
                id="home.SearchClients"
                defaultMessage="Rechercher un client ..."
            >
                {(placeholder) => (
                    <input
                        type="text"
                        className={`mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-full placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-black`}
                        style={{
                            minWidth: 350,
                        }}
                        placeholder={placeholder}
                        value={searchTerm}
                        onChange={(e) => {
                            setSearchTerm(e.target.value);
                            setShowSuggestions(true);
                        }}
                        onBlur={() => setShowSuggestions(false)} // Hide suggestions on blur
                        onFocus={() => setShowSuggestions(true)} // Show suggestions on focus
                    />
                )}
            </FormattedMessage>

            {/* Show suggestions if any clients match the search term */}
            {showSuggestions && filteredClients.length > 0 && (
                <div className="absolute top-full w-full bg-white border border-slate-300 rounded-lg shadow-lg max-h-60 overflow-y-auto mt-2 z-10 text-left">
                    {filteredClients.map((client) => (
                        <div
                            key={client.client_id}
                            onMouseDown={(e) => {
                                e.preventDefault(); // Prevent any unwanted behavior
                                handleClientClick(client.client_id);
                            }}
                            className="cursor-pointer px-3 py-2 hover:bg-slate-100 border-b border-gray-200"
                        >
                            <div className="flex flex-row items-end">
                                <div className="text-base text-black w-2/3">
                                    {client.client_name},{" "}
                                    <span className="italic text-sm text-gray-700">
                                        {client.city}
                                    </span>
                                </div>
                                <div className="ml-5 text-base text-gray-700 w-1/3">
                                    ID:{client.client_id}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ClientSearch;
