import React from "react";

const DisplayReport = ({ reportLayout, report, language }) => {
    const translations = {
        en: {
            interest_expressed: "Interest Expressed",
            discussed: "Discussed",
        },
        fr: {
            interest_expressed: "Intérêt manifesté",
            discussed: "Discutée",
            transmitted: "Transmis",
            to_transmit: "A transmettre",
            specific_interest: "Intérêt spécifique",
            product_interest: "Intérêt produit",
            comments: "Commentaires",
        },

        // Add more languages as needed
    };
    return (
        <div className="grid grid-cols-1 md:grid-cols-1 gap-4">
            {Object.entries(JSON.parse(report.responses)).map(
                ([fieldId, fieldValue]) => {
                    // Find the field based on meeting_type_id and field_id
                    const meetingTypeId =
                        report?.meeting?.meeting_type_id ||
                        report?.meeting_type_id; // Fallback to ensure the correct meeting_type_id is used

                    const field = reportLayout
                        .filter(
                            (item) => item.meeting_type_id === meetingTypeId
                        ) // Filter by meeting_type_id
                        .flatMap((item) => item.fields) // Flatten fields array
                        .find((field) => field.field_id === fieldId); // Find the field by field_id

                    // Set the fieldName to either the field's name or fieldId if not found
                    const fieldName = field ? field.field_name : fieldId;

                    // Skip fields with empty values
                    if (!fieldValue) return null;

                    let displayValue = fieldValue; // Default display is just the value
                    let additionalColumns = []; // To hold additional object details if present

                    // Handle object values (like Hihi, Haha)
                    if (typeof fieldValue === "object") {
                        additionalColumns = Object.entries(fieldValue).map(
                            ([key, value]) => (
                                <div key={key} className="flex flex-row">
                                    <span className="font-medium w-1/3">
                                        {key}:
                                    </span>
                                    <div className="pl-4 text-left w-2/3">
                                        {typeof value === "object" ? (
                                            <div className="pl-4">
                                                {Object.entries(value).map(
                                                    ([subKey, subValue]) => (
                                                        <div key={subKey}>
                                                            <span className="font-medium">
                                                                {translations[
                                                                    language
                                                                ]?.[subKey] ||
                                                                    subKey}
                                                                :
                                                            </span>
                                                            <span className="font-semibold ml-4">
                                                                {typeof subValue ===
                                                                "boolean"
                                                                    ? subValue
                                                                        ? language ===
                                                                          "fr"
                                                                            ? "Oui"
                                                                            : "Yes"
                                                                        : language ===
                                                                          "fr"
                                                                        ? "Non"
                                                                        : "No"
                                                                    : subValue}
                                                            </span>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        ) : (
                                            <span className="font-semibold">
                                                {typeof value === "boolean"
                                                    ? value
                                                        ? language === "fr"
                                                            ? "Oui"
                                                            : "Yes"
                                                        : language === "fr"
                                                        ? "Non"
                                                        : "No"
                                                    : value}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            )
                        );
                        displayValue = null; // We don't need to display this value in the second column anymore
                    }

                    // For non-object values, just display the value
                    if (
                        typeof fieldValue !== "object" &&
                        typeof fieldValue !== "boolean"
                    ) {
                        displayValue = fieldValue;
                    }

                    return (
                        <div
                            key={fieldId}
                            className="grid grid-cols-1 md:grid-cols-2 gap-4"
                        >
                            <span className="font-medium text-gray-700">
                                {fieldName}:
                            </span>
                            <div className="flex flex-col">
                                <span className="text-gray-600">
                                    {displayValue}
                                </span>
                                {additionalColumns.length > 0 && (
                                    <div className="flex flex-col mt-2">
                                        {additionalColumns}
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                }
            )}
        </div>
    );
};

export default DisplayReport;
