import React, { useState, useEffect } from "react";
import { FormattedMessage, IntlProvider } from "react-intl";
import { FiChevronUp, FiChevronDown } from "react-icons/fi"; // Import the required icons
import messages_en from "./translations/en.json";
import messages_pt from "./translations/pt.json";
import messages_fr from "./translations/fr.json";

const messages = {
    en: messages_en,
    pt: messages_pt,
    fr: messages_fr,
};

const CustomerDetails = ({ customer }) => {
    const [locale, setLocale] = useState();
    const [showProducts, setShowProducts] = useState({
        contactInfo: true, // First group is open by default
        addressContact: false,
        salesType: false,
        contractInfo: false,
        churnProbability: false,
    });

    useEffect(() => {
        setLocale(localStorage.getItem("language"));
    }, []);

    const toggleGroup = (group) => {
        setShowProducts((prev) => ({ ...prev, [group]: !prev[group] }));
    };

    // Function to format `visit_hours` from 945.0 to "HH:MM"
    const formatVisitHours = (visitHours) => {
        if (!visitHours) return null; // Handle missing or null values
        const visitString = Math.floor(visitHours).toString(); // Convert to string
        const hours = visitString.slice(0, -2).padStart(2, "0"); // Extract hours and pad
        const minutes = visitString.slice(-2).padStart(2, "0"); // Extract minutes and pad
        return `${hours}:${minutes}`; // Format as HH:MM
    };

    // Function to translate numeric days into French names
    const getVisitDays = (visitDays) => {
        const dayMapping = [
            "Lundi", // Monday
            "Mardi", // Tuesday
            "Mercredi", // Wednesday
            "Jeudi", // Thursday
            "Vendredi", // Friday
            "Samedi", // Saturday
            "Dimanche", // Sunday
        ];

        let parsedDays = [];

        try {
            // If visitDays is a string, try parsing it as JSON
            if (typeof visitDays === "string") {
                parsedDays = JSON.parse(visitDays);
            } else if (Array.isArray(visitDays)) {
                parsedDays = visitDays; // Already an array
            } else {
                throw new Error(
                    "visitDays is not a valid array or JSON string."
                );
            }
        } catch (error) {
            console.error("Failed to parse visitDays:", error);
            parsedDays = []; // Default to an empty array on error
        }

        // Ensure parsedDays is an array of valid day numbers before mapping
        return parsedDays
            .filter((day) => typeof day === "number" && day >= 0 && day < 7) // Validate day numbers
            .map((day) => dayMapping[day]); // Map numbers to French day names
    };

    // Group configurations for conditional rendering
    const groups = [
        {
            id: "contactInfo",
            title: "client.general_info",
            fields: [
                {
                    label: "client.customer_type",
                    value: customer.client_level_1,
                },
                { label: "client.address", value: customer.address_1 },
                { label: "client.zip_code", value: customer.zip_code },
                { label: "client.city", value: customer.city },
                { label: "client.season_start", value: customer.season_start },
                { label: "client.season_end", value: customer.season_end },
                // { label: "client.is_seasonal", value: customer.is_seasonal },
            ],
        },
        {
            id: "addressContact",
            title: "client.contact_info",
            fields: [
                {
                    label: "client.contact_name",
                    value: customer.purchasing_manager,
                },
                { label: "client.contact_number", value: customer.phone },
                { label: "client.mail", value: customer.mail },
                { label: "client.website", value: customer.website_url },
            ],
        },
        // {
        //     id: "salesType",
        //     title: "client.digital",
        //     fields: [
        //         { label: "client.portail", value: "Oui" },
        //         {
        //             label: "client.menu",
        //             value: customer.menu_url,
        //         },
        //     ],
        // },
        {
            id: "contractInfo",
            title: "client.contract_info",
            fields: [
                {
                    label: "client.outstandings",
                    value: customer.outstandings,
                },
                {
                    label: "client.payment_type",
                    value: customer.payment_type,
                },
                {
                    label: "client.payment_delay",
                    value: customer.payment_delay,
                },
                {
                    label: "client.contract_type",
                    value: customer.id === 1 ? "Bière" : "-",
                },
                {
                    label: "client.contract_value",
                    value: customer.id === 1 ? "50 000 €" : "-",
                },
            ],
        },
        {
            id: "visitFrequency",
            title: "client.client_coverage",
            fields: [
                {
                    label: "client.visit_frequency",
                    value: customer.visit_frequency,
                },
                {
                    label: "client.visit_days",
                    value: getVisitDays(customer.visit_days).join(", "),
                },
                {
                    label: "client.visit_hours",
                    value: "-",
                },

                {
                    label: "client.call_days",
                    value: "-",
                },
                {
                    label: "client.call_hours",
                    value: formatVisitHours(customer.visit_hours),
                },
                {
                    label: "client.delivery_days",
                    value: "-",
                },
            ],
        },
    ];

    return (
        customer && (
            <IntlProvider locale={locale} messages={messages[locale]}>
                <div className="mt-2 text-black flex-col w-5/6">
                    {groups.map((group) => {
                        // Only render the group if it has fields
                        const hasFields = group.fields.some(
                            (field) => field.value
                        ); // Check if at least one field has a value
                        return (
                            hasFields && (
                                <div className="mb-2" key={group.id}>
                                    <div
                                        className="flex flex-row items-center cursor-pointer w-full border-b border-gray-200"
                                        onClick={() => toggleGroup(group.id)}
                                    >
                                        <h1 className="w-4/5 font-semibold text-sm text-black">
                                            <FormattedMessage
                                                id={group.title}
                                            />
                                        </h1>
                                        <div className="w-1/5 ml-2 text-lg">
                                            {showProducts[group.id] ? (
                                                <FiChevronUp />
                                            ) : (
                                                <FiChevronDown />
                                            )}
                                        </div>
                                    </div>
                                    {showProducts[group.id] && (
                                        <div className="flex flex-col w-full">
                                            {group.fields.map(
                                                (field, index) => (
                                                    <div
                                                        className="mr-2 text-xs flex flex-row w-full mt-1"
                                                        style={{
                                                            minWidth: 120,
                                                        }}
                                                        key={index}
                                                    >
                                                        <div className="flex font-semibold w-1/2">
                                                            <FormattedMessage
                                                                id={field.label}
                                                            />
                                                            {":"}
                                                        </div>
                                                        <div
                                                            className="flex w-1/2"
                                                            style={field.style}
                                                        >
                                                            {field.value &&
                                                            field.value.startsWith(
                                                                "https"
                                                            ) ? (
                                                                <a
                                                                    href={
                                                                        field.value
                                                                    }
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    className="text-blue-500 underline"
                                                                >
                                                                    Lien
                                                                </a>
                                                            ) : (
                                                                field.value
                                                            )}
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    )}
                                </div>
                            )
                        );
                    })}
                </div>
            </IntlProvider>
        )
    );
};

export default CustomerDetails;
