import React from 'react';
import { createRoot } from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import App from './App';
import './index.css';
import build_param from './build_params.json';
import { isPlatform } from "@ionic/react";


const frontendPath = build_param.frontendPath;
const Auth0_domain = build_param.domain;
const Auth0_clientId = build_param.client_id;
const root = createRoot(document.getElementById('root'));

const iosOrAndroid = isPlatform('hybrid');

const callbackUri = iosOrAndroid
  ? "com.mywaydev.app://auth-dev.myway.technology/capacitor/com.mywaydev.app/callback"
  : `${frontendPath}`;

console.log(callbackUri)

root.render(
  // <React.StrictMode>
    <Auth0Provider
      domain={Auth0_domain}
      clientId={Auth0_clientId}
      useRefreshTokens={true}
      cacheLocation="localstorage"
      authorizationParams={{
        redirect_uri: callbackUri,
        audience: "https://www.api.myway.technology",
        scope: "openid profile email read:appointments offline_access"
      }}
    >
      <App />
    </Auth0Provider>
  // </React.StrictMode>
);
