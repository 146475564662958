/*eslint-disable*/

import React, { useState, useEffect } from "react";

import FastAPIClient from "../../../client";
import config from "../../../config";
const client = new FastAPIClient(config);
const { v4: uuidv4 } = require("uuid");

import { FormattedMessage, IntlProvider } from "react-intl";
import messages_en from "./translations/en.json";
import messages_fr from "./translations/fr.json";
import ReminderModal from "../../ModalComponents/ReminderModal";

import { FiChevronUp, FiChevronDown } from "react-icons/fi";

const messages = {
    en: messages_en,
    fr: messages_fr,
};

const ButtonComponent = ({ selectedValue, onChange }) => {
    const handleButtonClick = (value) => {
        onChange(value);
    };

    // Styles for the buttons
    const getButtonStyles = (value) => {
        if (selectedValue === null) {
            return "bg-white border border-gray-600 text-gray-600"; // Both gray if none is selected
        }
        if (selectedValue === value) {
            return value === "oui"
                ? "bg-gold text-black" // Oui selected
                : "bg-red-500 text-white"; // Non selected
        }
        return "bg-white border border-gray-600 text-gray-600"; // Default gray
    };

    return (
        <div className="flex space-x-4">
            <button
                onClick={() => handleButtonClick("oui")}
                className={`py-1 rounded-full px-6 ${getButtonStyles("oui")}`}
            >
                Oui
            </button>
            <button
                onClick={() => handleButtonClick("non")}
                className={`py-1 rounded-full px-6 ${getButtonStyles("non")}`}
            >
                Non
            </button>
        </div>
    );
};

const DiscussedProductCategories = ({
    field_name, // Field name
    field_id, // Field id
    handleInputChange,
    responses,
    options, // Options to render rows
}) => {
    const [expandedOptions, setExpandedOptions] = useState({});

    const handleCheckboxChange = (optionValue, key) => {
        const currentResponse = responses[field_id] || {};
        const updatedOptionResponse = {
            ...currentResponse[optionValue],
            [key]: !currentResponse[optionValue]?.[key],
        };

        const newResponse = {
            ...currentResponse,
            [optionValue]: updatedOptionResponse,
        };

        handleInputChange(field_id, newResponse);
    };

    const handleTextChange = (optionValue, key, value) => {
        const currentResponse = responses[field_id] || {};
        const updatedOptionResponse = {
            ...currentResponse[optionValue],
            [key]: value,
        };

        const newResponse = {
            ...currentResponse,
            [optionValue]: updatedOptionResponse,
        };

        handleInputChange(field_id, newResponse);
    };

    const toggleExpandedOption = (optionLabel) => {
        setExpandedOptions((prevState) => ({
            ...prevState,
            [optionLabel]: !prevState[optionLabel],
        }));
    };

    return (
        <div className="flex flex-col gap-4 w-full">
            <div className="text-left w-full font-semibold">{field_name}</div>

            {options.map((option, index) => (
                <div
                    key={`${field_id}-${index}`} // Unique key for each row
                    className="flex flex-col md:flex-row text-left gap-4 items-center w-full"
                >
                    {/* Option Name and Toggle Button for mobile */}
                    <div className="flex justify-between items-center w-full md:w-1/6">
                        <div>{option.label}</div>

                        {/* Toggle Button for mobile */}
                        <button
                            className="md:hidden flex items-center"
                            onClick={() => toggleExpandedOption(option.label)}
                        >
                            {expandedOptions[option.label] ? (
                                <FiChevronUp size={24} />
                            ) : (
                                <FiChevronDown size={24} />
                            )}
                        </button>
                    </div>

                    {/* Option details - This part will toggle visibility on mobile */}
                    {(expandedOptions[option.label] ||
                        window.innerWidth >= 768) && (
                        <div className="flex flex-col md:flex-row gap-4 w-full">
                            {/* Discutée Checkbox */}
                            <label className="flex items-center gap-2 w-full md:w-1/6">
                                <input
                                    type="checkbox"
                                    checked={
                                        responses[field_id]?.[option.label]
                                            ?.discussed || false
                                    }
                                    onChange={() =>
                                        handleCheckboxChange(
                                            option.label,
                                            "discussed"
                                        )
                                    }
                                />
                                Discutée
                            </label>

                            {/* Intérêt manifesté Checkbox */}
                            <label className="flex items-center gap-2 w-full md:w-1/6">
                                <input
                                    type="checkbox"
                                    checked={
                                        responses[field_id]?.[option.label]
                                            ?.interest_expressed || false
                                    }
                                    onChange={() =>
                                        handleCheckboxChange(
                                            option.label,
                                            "interest_expressed"
                                        )
                                    }
                                />
                                Intérêt manifesté
                            </label>

                            {/* Intérêt spécifique Text Input */}
                            <input
                                type="text"
                                value={
                                    responses[field_id]?.[option.label]
                                        ?.specific_interest || ""
                                }
                                onChange={(e) =>
                                    handleTextChange(
                                        option.label,
                                        "specific_interest",
                                        e.target.value
                                    )
                                }
                                placeholder="Intérêt spécifique"
                                className="border border-gray-400 rounded-md p-2 w-full md:w-1/6"
                            />

                            {/* Intérêt Produit Text Input */}
                            <input
                                type="text"
                                value={
                                    responses[field_id]?.[option.label]
                                        ?.product_interest || ""
                                }
                                onChange={(e) =>
                                    handleTextChange(
                                        option.label,
                                        "product_interest",
                                        e.target.value
                                    )
                                }
                                placeholder="Intérêt Produit"
                                className="border border-gray-400 rounded-md p-2 w-full md:w-1/6"
                            />

                            {/* Commentaires Text Input */}
                            <input
                                type="text"
                                value={
                                    responses[field_id]?.[option.label]
                                        ?.comments || ""
                                }
                                onChange={(e) =>
                                    handleTextChange(
                                        option.label,
                                        "comments",
                                        e.target.value
                                    )
                                }
                                placeholder="Commentaires"
                                className="border border-gray-400 rounded-md p-2 w-full md:w-1/6"
                            />
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

const Negociation = ({
    field_name, // Field name
    field_id, // Field id
    handleInputChange,
    responses,
    options, // Options to render rows
}) => {
    const handleCheckboxChange = (optionValue, key) => {
        const currentResponse = responses[field_id] || {};
        const updatedOptionResponse = {
            ...currentResponse[optionValue],
            [key]: !currentResponse[optionValue]?.[key],
        };

        const newResponse = {
            ...currentResponse,
            [optionValue]: updatedOptionResponse,
        };

        handleInputChange(field_id, newResponse);
    };

    const handleTextChange = (optionValue, key, value) => {
        const currentResponse = responses[field_id] || {};
        const updatedOptionResponse = {
            ...currentResponse[optionValue],
            [key]: value,
        };

        const newResponse = {
            ...currentResponse,
            [optionValue]: updatedOptionResponse,
        };

        handleInputChange(field_id, newResponse);
    };

    return (
        <div className="flex flex-col gap-4 w-full">
            <div className="text-left w-full font-semibold">
                Catégories de produits discutées
            </div>

            {options.map((option, index) => (
                <div
                    key={`${field_id}-${index}`} // Unique key for each row
                    className="flex flex-col md:flex-row gap-4 p-4 items-center"
                >
                    {/* Option Name */}
                    <div className="w-full md:w-1/6 text-left">
                        {option.label} {/* Render the label for the option */}
                    </div>

                    {/* Discutée Checkbox */}
                    <label className="flex items-center gap-2 w-full md:w-1/6">
                        <input
                            type="checkbox"
                            checked={
                                responses[field_id]?.[option.label]
                                    ?.discussed || false
                            }
                            onChange={() =>
                                handleCheckboxChange(option.label, "discussed")
                            }
                        />
                        Négociation
                    </label>

                    {/* Commentaires Text Input */}
                    <input
                        type="text"
                        value={
                            responses[field_id]?.[option.label]?.comments || ""
                        }
                        onChange={(e) =>
                            handleTextChange(
                                option.label,
                                "comments",
                                e.target.value
                            )
                        }
                        placeholder="Commentaires"
                        className="border border-gray-400 rounded-md p-2 w-full md:w-1/6"
                    />
                </div>
            ))}
        </div>
    );
};

const DiscussedPromotions = ({
    field_name, // Field name
    field_id, // Field id
    handleInputChange,
    responses,
    options, // Options to render rows
}) => {
    const [expandedOptions, setExpandedOptions] = useState({});

    const handleCheckboxChange = (optionValue, key) => {
        const newResponse = {
            ...responses[field_id],
            [optionValue]: {
                ...responses[field_id]?.[optionValue],
                [key]: !responses[field_id]?.[optionValue]?.[key],
            },
        };
        handleInputChange(field_id, newResponse);
    };

    const toggleExpandedOption = (optionLabel) => {
        setExpandedOptions((prevState) => ({
            ...prevState,
            [optionLabel]: !prevState[optionLabel],
        }));
    };

    return (
        <div className="flex flex-col gap-4 w-full">
            <div className="text-left w-full font-semibold">{field_name}</div>

            {options.map((option, index) => (
                <div
                    key={`${field_id}-${index}`}
                    className="flex flex-col md:flex-row gap-2 p-4 items-center w-full"
                >
                    {/* Option Name and Toggle Button for mobile */}
                    <div className="flex justify-between items-center text-left w-full md:w-1/6">
                        <div>{option.label}</div>

                        {/* Toggle Button for mobile */}
                        <button
                            className="md:hidden flex items-center"
                            onClick={() => toggleExpandedOption(option.label)}
                        >
                            {expandedOptions[option.label] ? (
                                <FiChevronUp size={24} />
                            ) : (
                                <FiChevronDown size={24} />
                            )}
                        </button>
                    </div>

                    {/* Option details - This part will toggle visibility on mobile */}
                    {(expandedOptions[option.label] ||
                        window.innerWidth >= 768) && (
                        <div className="flex flex-col md:flex-row gap-4 w-full">
                            {/* Discutée Checkbox */}
                            <label className="flex items-center gap-2 w-full md:w-1/6">
                                <input
                                    type="checkbox"
                                    checked={
                                        responses[field_id]?.[option.label]
                                            ?.discussed || false
                                    }
                                    onChange={() =>
                                        handleCheckboxChange(
                                            option.label,
                                            "discussed"
                                        )
                                    }
                                />
                                Discutée
                            </label>

                            {/* Intérêt manifesté Checkbox */}
                            <label className="flex items-center gap-2 w-full md:w-1/6">
                                <input
                                    type="checkbox"
                                    checked={
                                        responses[field_id]?.[option.label]
                                            ?.interest_expressed || false
                                    }
                                    onChange={() =>
                                        handleCheckboxChange(
                                            option.label,
                                            "interest_expressed"
                                        )
                                    }
                                />
                                Intérêt manifesté
                            </label>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
};

const CustomerSatisfaction = ({
    field_name, // Field name
    field_id, // Field id
    handleInputChange,
    responses,
    options, // Options to render rows
}) => {
    const handleCheckboxChange = (optionValue, key) => {
        const newResponse = {
            ...responses[field_id],
            [optionValue]: {
                ...responses[field_id]?.[optionValue],
                [key]: !responses[field_id]?.[optionValue]?.[key],
            },
        };
        handleInputChange(field_id, newResponse);
    };

    return (
        <div className="flex flex-col gap-4 w-full">
            <div className="text-left w-full font-semibold">
                Satisfaction client
            </div>

            {options.map((option, index) => (
                <div
                    key={`${field_id}-${index}`}
                    className="flex flex-col md:flex-row gap-4 p-4 items-center"
                >
                    {/* Option Name */}
                    <div className="w-full md:w-1/6 text-left">
                        {option.label}
                    </div>

                    {/* Discutée Checkbox */}
                    <label className="flex items-center gap-2 w-full md:w-1/6">
                        <input
                            type="checkbox"
                            checked={
                                responses[field_id]?.[option.label]
                                    ?.discussed || false
                            }
                            onChange={() =>
                                handleCheckboxChange(option.label, "discussed")
                            }
                        />
                        À améliorer
                    </label>

                    {/* Intérêt manifesté Checkbox */}
                    <label className="flex items-center gap-2 w-full md:w-1/6">
                        <input
                            type="checkbox"
                            checked={
                                responses[field_id]?.[option.label]
                                    ?.interest_expressed || false
                            }
                            onChange={() =>
                                handleCheckboxChange(
                                    option.label,
                                    "interest_expressed"
                                )
                            }
                        />
                        En ligne avec les attentes
                    </label>

                    <label className="flex items-center gap-2 w-full md:w-1/6">
                        <input
                            type="checkbox"
                            checked={
                                responses[field_id]?.[option.label]
                                    ?.interest_expressed || false
                            }
                            onChange={() =>
                                handleCheckboxChange(
                                    option.label,
                                    "interest_expressed"
                                )
                            }
                        />
                        Très satisfait
                    </label>
                </div>
            ))}
        </div>
    );
};

const SubmittedDocuments = ({ options, handleInputChange, responses }) => {
    const handleCheckboxChange = (optionValue, key) => {
        const currentResponse = responses.provided_documents || {};
        const updatedOptionResponse = {
            ...currentResponse[optionValue],
            [key]: !currentResponse[optionValue]?.[key],
        };

        const newResponse = {
            ...currentResponse,
            [optionValue]: updatedOptionResponse,
        };

        handleInputChange("provided_documents", newResponse);
    };

    return (
        <div className="flex flex-col gap-4 w-full">
            <div className="text-left w-full font-semibold">
                Sélectionner les documents et informations transmises lors du
                RDV et celles à fournir aux prochaines étapes
            </div>

            {options.map((option, index) => (
                <div
                    key={`doc-${index}`}
                    className="flex flex-col md:flex-row gap-4 p-4 items-center"
                >
                    {/* Option Name */}
                    <div className="w-full md:w-1/6 text-left">
                        {option.label}
                    </div>

                    {/* Transmis (Checkbox for "Transmitted") */}
                    <label className="flex items-center gap-2 w-full md:w-1/6">
                        <input
                            type="checkbox"
                            checked={
                                responses.provided_documents?.[option.label]
                                    ?.transmitted || false
                            }
                            onChange={() =>
                                handleCheckboxChange(
                                    option.label,
                                    "transmitted"
                                )
                            }
                        />
                        Transmis
                    </label>

                    {/* A transmettre (Checkbox for "To be transmitted") */}
                    <label className="flex items-center gap-2 w-full md:w-1/6">
                        <input
                            type="checkbox"
                            checked={
                                responses.provided_documents?.[option.label]
                                    ?.to_transmit || false
                            }
                            onChange={() =>
                                handleCheckboxChange(
                                    option.label,
                                    "to_transmit"
                                )
                            }
                        />
                        A transmettre
                    </label>
                </div>
            ))}
        </div>
    );
};

const KeyValueList = ({ field_id, handleInputChange, responses }) => {
    // Initialize the distributors and categories from responses, or use an empty list
    const distributors = responses[field_id] || [
        { distributor: "", category: "" },
    ];

    // Add a new distributor and category pair
    const handleAddDistributor = () => {
        const updatedDistributors = [
            ...distributors,
            { distributor: "", category: "" },
        ];
        handleInputChange(field_id, updatedDistributors); // Update responses with the new list
    };

    // Handle changes in distributor or category
    const handleDistributorChange = (index, field, value) => {
        const updatedDistributors = [...distributors];
        updatedDistributors[index][field] = value;
        handleInputChange(field_id, updatedDistributors); // Update responses with the new list
    };

    return (
        <div className="w-full flex flex-row">
            <div className="w-0 md:w-1/2"></div>
            <div className="w-full md:w-1/2">
                {distributors.map((item, index) => (
                    <div
                        key={index}
                        className="distributor-category-row flex flex-col md:flex-row mt-3 w-full gap-5"
                    >
                        <div className="flex flex-row">
                            <input
                                type="text"
                                placeholder="Distributor Name"
                                value={item.distributor}
                                onChange={(e) =>
                                    handleDistributorChange(
                                        index,
                                        "Distributeur",
                                        e.target.value
                                    )
                                }
                                className="flex-1 w-1/3 input border border-gray-200 rounded-md p-2"
                            />
                            <input
                                type="text"
                                placeholder="Product Category"
                                value={item.category}
                                onChange={(e) =>
                                    handleDistributorChange(
                                        index,
                                        "Categorie",
                                        e.target.value
                                    )
                                }
                                className="flex-1 w-1/3 input border border-gray-200 rounded-md p-2 ml-5"
                            />
                        </div>
                        {/* Remove button for each row */}
                        <button
                            type="button"
                            onClick={() => {
                                const updatedDistributors = distributors.filter(
                                    (_, i) => i !== index
                                );
                                handleInputChange(
                                    field_id,
                                    updatedDistributors
                                ); // Update responses with the new list
                            }}
                            className="flex-1 md:w-1/4 border border-black rounded-full p-2 ml-5"
                        >
                            - Supprimer
                        </button>
                    </div>
                ))}

                {/* Add New Distributor and Category Row */}
                <button
                    type="button"
                    onClick={handleAddDistributor}
                    className="add-btn border border-black rounded-full w-full p-2 mt-5"
                >
                    + Ajouter
                </button>
            </div>
        </div>
    );
};

const ReportForm = ({
    reportlayout,
    setToggle,
    clientId,
    userId,
    meetingId,
    userName,
    meeting_type_id,
    locale = "fr",
    setErrorMessage,
    setErrorMessageType,
}) => {
    const [selectedLayout, setSelectedLayout] = useState(null);
    const [responses, setResponses] = useState({});
    const [reminderData, setReminderData] = useState({
        reminder_id: uuidv4(),
        start_date: null,
        user_id: "1", // Replace with the user's ID if applicable
        title: "",
        comment: "",
    });
    const [isReminderOpen, setIsReminderOpen] = useState(false);

    // Sample reports data (you can extend this structure with more report types)

    useEffect(() => {
        if (meeting_type_id) {
            setSelectedLayout(String(meeting_type_id)); // Ensure it's a string
        }
    }, [meeting_type_id]);

    const language = "fr"; // This can be dynamically set based on user preference

    const translations = {
        en: {
            interest_expressed: "Interest Expressed",
            discussed: "Discussed",
        },
        fr: {
            interest_expressed: "Intérêt manifesté",
            discussed: "Discutée",
            transmitted: "Transmis",
            to_transmit: "A transmettre",
            specific_interest: "Intérêt spécifique",
            product_interest: "Intérêt produit",
            comments: "Commentaires",
            provided_documents: "Documents transmis",
        },

        // Add more languages as needed
    };

    // Get current date formatted as MM/DD/YYYY
    const currentDate = new Date().toLocaleDateString("fr-FR");

    // Handle input changes and update the response state
    const handleInputChange = (fieldName, value) => {
        setResponses((prev) => {
            const newResponses = { ...prev, [fieldName]: value };

            // Check the field that is being updated
            const field = reportlayout[selectedLayout]?.find(
                (field) => field.field_id === fieldName
            );

            // If this field has no visibility condition, check for its dependencies
            if (field) {
                // Iterate through all the fields in the report to find those that depend on the current field
                reportlayout[selectedLayout].forEach((f) => {
                    if (
                        f.visibility_conditions &&
                        f.visibility_conditions.field_id === fieldName
                    ) {
                        const conditionValue = f.visibility_conditions.value;

                        // If the field is conditionally visible based on the value of the current field
                        // and the condition is no longer satisfied, clear the field
                        if (
                            (conditionValue === "yes" && value === "no") ||
                            (conditionValue === "no" && value === "yes")
                        ) {
                            newResponses[f.field_id] = ""; // Clear the dependent field value
                        }
                    }
                });
            }

            return newResponses;
        });
    };

    // Handle form submission
    const handleSaveVisit = async () => {
        const payload = {
            report_id: uuidv4(),
            meeting_id: meetingId || uuidv4(),
            meeting_type_id: selectedLayout,
            client_id: clientId,
            user_id: userId,
            responses: JSON.stringify(responses), // Save the responses as a JSON string
            created_at: new Date().toISOString(),
        };
        const reminder = {
            reminder_id: reminderData.reminder_id,
            date: reminderData.start_date,
            title: reminderData.title,
            user_id: userId,
            note: reminderData.comment,
            client_id: clientId,
            created_at: new Date().toISOString(),
        };

        // Send data to backend
        // Check if the reminder is open and all required reminder fields are filled
        // Check if the reminder is open and all required reminder fields are filled
        if (isReminderOpen) {
            if (
                reminderData.start_date &&
                reminderData.title &&
                reminderData.comment
            ) {
                console.log("Reminder data", reminder);
                await client.createReminder(reminder);
                setErrorMessage(null); // Clear error if reminder is saved successfully
                console.log("Final Report", payload);
                await client.saveReport(payload);

                setToggle(false);
                setErrorMessage("Les données ont été enregistrées."); // Show error message
                setErrorMessageType("success"); // Show error message
                setTimeout(() => {
                    setErrorMessage(null); // Clear error after 3 seconds
                }, 3000);
            } else {
                console.log("Reminder fields incomplete, not saving reminder.");
                setErrorMessage(
                    "Veuillez remplir tous les champs du rappel avant de sauvegarder."
                ); // Show error message
                setErrorMessageType("error"); // Show error message
                setTimeout(() => {
                    setErrorMessage(null); // Clear error after 3 seconds
                }, 3000);
            }
        } else {
            console.log("Reminder not open, not saving reminder.");
            console.log("payload", payload);
            await client.saveReport(payload);
            setToggle(false);
            setErrorMessage("Les données ont été enregistrées."); // Show error message
            setErrorMessageType("success"); // Show error message
            setTimeout(() => {
                setErrorMessage(null); // Clear error after 3 seconds
            }, 3000);
        }
    };

    // Render the fields dynamically based on report type and visibility conditions
    // Render the fields dynamically based on report type and visibility conditions
    const renderReportFields = () => {
        // const selectedReport = reports[selectedLayout] || [];
        let selectedReport = [];

        // Filter the rows where meeting_type_id matches the selectedLayout
        const filteredRows = reportlayout.filter(
            (item) => item.meeting_type_id === selectedLayout
        );

        if (filteredRows.length > 0) {
            // Extract the fields arrays from the filtered rows
            const fields = filteredRows.map((item) => item.fields);
            selectedReport = fields[0];
        }

        // console.log("fields", fields[0]); // Output the array of fields arrays

        console.log("selectedReport", selectedReport);

        return selectedReport.map((field) => {
            const {
                field_id,
                field_name,
                input_type,
                visibility_conditions: rawVisibilityConditions, // Store the raw text value
                input_options,
            } = field;

            console.log("Raw visibility_conditions:", rawVisibilityConditions);

            let visibility_conditions = null;

            try {
                visibility_conditions = JSON.parse(rawVisibilityConditions); // Convert string to object
            } catch (error) {
                console.error("Error parsing visibility_conditions:", error);
            }

            // Check visibility conditions
            if (visibility_conditions) {
                const conditionField =
                    responses[visibility_conditions.field_id];

                if (conditionField !== visibility_conditions.value) {
                    return null; // Don't render this field if visibility condition isn't met
                }
            }

            return (
                <div key={field_id} className="w-full mt-5">
                    {input_type === "yes_no" && (
                        <div className="flex flex-wrap md:flex-nowrap gap-4 text-left">
                            {/* Label */}
                            <label
                                htmlFor={field_id}
                                className="w-full md:w-1/3 font-semibold"
                            >
                                {field_name}
                            </label>

                            {/* Input (ButtonComponent for yes/no input) */}

                            <div className="w-full md:w-2/3 mt-2 md:mt-0">
                                <ButtonComponent
                                    selectedValue={responses[field_id]}
                                    onChange={(value) =>
                                        handleInputChange(field_id, value)
                                    }
                                />
                            </div>
                        </div>
                    )}

                    {input_type === "dropdown" && (
                        <select
                            id={field_id}
                            value={responses[field_id] || ""}
                            onChange={(e) =>
                                handleInputChange(field_id, e.target.value)
                            }
                            className="mt-2 p-2 border border-gray-300 rounded-full flex flex-row"
                        >
                            <option value="">Sélectionnez une option.</option>
                            {JSON.parse(input_options).map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    )}

                    {input_type === "discussed_product_categories" && (
                        <DiscussedProductCategories
                            field_name={field_name} // Pass the field name
                            field_id={field_id} // Pass the field id
                            handleInputChange={handleInputChange} // Pass the input change handler
                            responses={responses} // Pass the responses
                            options={JSON.parse(input_options)}
                        />
                    )}
                    {input_type === "discussed_promotions" && (
                        <DiscussedPromotions
                            field_name={field_name} // Pass the field name
                            field_id={field_id} // Pass the field id
                            handleInputChange={handleInputChange} // Pass the input change handler
                            responses={responses} // Pass the responses
                            options={JSON.parse(input_options)}
                        />
                    )}

                    {/* Render date input */}
                    {input_type === "date" && (
                        <div className="flex flex-wrap md:flex-nowrap gap-4 text-left">
                            {/* Label */}
                            <label
                                htmlFor={field_id}
                                className="w-full md:w-1/2 font-semibold"
                            >
                                {field_name}
                            </label>
                            <input
                                type="date"
                                id={field_id}
                                value={responses[field_id] || ""}
                                onChange={(e) =>
                                    handleInputChange(field_id, e.target.value)
                                }
                                className="mt-2 p-2 border border-gray-300 rounded-full"
                            />
                        </div>
                    )}

                    {input_type === "provided_documents" && (
                        <SubmittedDocuments
                            fields={[field]} // Pass an array with the current field
                            handleInputChange={handleInputChange} // Pass the input change handler
                            responses={responses} // Pass the responses
                            options={JSON.parse(input_options)}
                        />
                    )}

                    {input_type === "key_value_list" && (
                        <KeyValueList
                            field_id={field_id} // Pass the field_id
                            handleInputChange={handleInputChange} // Pass the input change handler
                            responses={responses} // Pass the responses
                        />
                    )}
                    {input_type === "product_negociation" && (
                        <Negociation
                            field_id={field_id} // Pass the field_id
                            handleInputChange={handleInputChange} // Pass the input change handler
                            responses={responses} // Pass the responses
                            options={JSON.parse(input_options)}
                        />
                    )}
                    {input_type === "customer_satisfaction" && (
                        <CustomerSatisfaction
                            field_id={field_id} // Pass the field_id
                            handleInputChange={handleInputChange} // Pass the input change handler
                            responses={responses} // Pass the responses
                            options={JSON.parse(input_options)}
                        />
                    )}
                </div>
            );
        });
    };

    // Handle layout change (select dropdown change)
    const handleLayoutChange = (e) => {
        setSelectedLayout(e.target.value);
    };

    // When selectedLayout changes, reset the responses to initial state
    useEffect(() => {
        setResponses({}); // Reset responses to initial state
    }, [selectedLayout]); // This will trigger every time `selectedLayout` changes

    useEffect(() => {
        console.log("responses", responses);
    }, [responses]);

    useEffect(() => {
        console.log("layout", reportlayout);
    }, [reportlayout]);

    return (
        <section className="w-full bg-white flex flex-col items-center mt-5  pl-5">
            <IntlProvider locale={locale} messages={messages[locale]}>
                <div className="flex flex-col w-full md:w-4/5 items-center mt-4">
                    <div className="mt-4 text-left text-xl w-full">
                        <FormattedMessage
                            id="report.title"
                            values={{ number: 1 }}
                        />
                    </div>
                    <div className="flex flex-col md:flex-row text-left w-full mt-5">
                        <div className="w-1/3 font-semibold">
                            <FormattedMessage
                                id="report.visit_date"
                                values={{ number: 1 }}
                            />
                        </div>
                        <div className="w-2/3 text-gray-600">{currentDate}</div>
                    </div>
                    <div className="flex flex-col md:flex-row text-left w-full mt-5">
                        <div className="w-1/3 font-semibold">
                            <FormattedMessage
                                id="report.attendees"
                                values={{ number: 1 }}
                            />
                        </div>
                        <div className="w-2/3 text-gray-600">{userName}</div>
                    </div>

                    {meetingId === null && (
                        <div className="mt-5 w-full flex flex-col md:flex-row md:items-center">
                            <div className="w-1/3">
                                <label
                                    htmlFor="layout"
                                    className="text-left block font-semibold"
                                >
                                    <FormattedMessage
                                        id="report.select_report"
                                        values={{ number: 1 }}
                                    />
                                </label>
                            </div>
                            <div className="w-2/3 text-left">
                                <select
                                    id="layout"
                                    className="border border-gray-300 p-2 rounded-full"
                                    value={selectedLayout}
                                    onChange={handleLayoutChange} // Use the handler here
                                >
                                    <option value="">
                                        {" "}
                                        <FormattedMessage
                                            id="report.select_report"
                                            values={{ number: 1 }}
                                        />
                                    </option>
                                    {reportlayout
                                        .filter(
                                            (layout) =>
                                                layout.meeting_type_id ===
                                                    "MT-01" ||
                                                layout.meeting_type_id ===
                                                    "MT-02" ||
                                                layout.meeting_type_id ===
                                                    "MT-03" ||
                                                layout.meeting_type_id ===
                                                    "MT-04" ||
                                                layout.meeting_type_id ===
                                                    "MT-05" ||
                                                layout.meeting_type_id ===
                                                    "MT-06" ||
                                                layout.meeting_type_id ===
                                                    "MT-07" ||
                                                layout.meeting_type_id ===
                                                    "MT-08"
                                        ) // Filter for MT-01 and MT-02
                                        .map((layout) => (
                                            <option
                                                key={layout.meeting_type_id}
                                                value={layout.meeting_type_id}
                                            >
                                                {layout.description}
                                            </option>
                                        ))}
                                </select>
                            </div>
                        </div>
                    )}

                    {/* Render dynamic report fields */}

                    {reportlayout.length > 0 && renderReportFields()}

                    <div className="mt-5 w-full flex flex-col md:flex-row md:items-center">
                        <div className="w-1/3"></div>
                        <div className="w-2/3 text-left">
                            {isReminderOpen == false && (
                                <button
                                    onClick={() => setIsReminderOpen(true)}
                                    className="border border-gray-200 text-black px-4 py-2 rounded-full"
                                >
                                    + Ajouter un rappel
                                </button>
                            )}

                            {isReminderOpen == true && (
                                <button
                                    onClick={() => setIsReminderOpen(false)}
                                    className="border border-gray-200 text-black px-4 py-2 rounded-full"
                                >
                                    Supprimer
                                </button>
                            )}
                        </div>
                    </div>

                    <div className="mt-5 w-full flex flex-col md:flex-row md:items-center">
                        <div className="w-1/2"></div>
                        <div className="w-1/2 text-left">
                            {isReminderOpen && (
                                <ReminderModal
                                    reminderData={reminderData}
                                    setNewEventData={setReminderData}
                                />
                            )}
                        </div>
                    </div>
                    {/* Show Modal Only When isReminderOpen is true */}

                    {/* Actions */}
                    <div className="flex flex-row w-full md:w-4/5 mt-5 mb-10">
                        <div className="w-0 md:w-3/4"></div>
                        <div className="w-1/2 md:w-1/4 flex items-center md:flex-col md:items-end">
                            <button
                                onClick={handleSaveVisit}
                                className="w-1/2 bg-black rounded-full text-white pl pr pt-1 pb-1 font-semibold md:align-right"
                            >
                                <FormattedMessage
                                    id="report.save"
                                    values={{ number: 1 }}
                                />
                            </button>
                        </div>
                        <div className="w-1/2 md:w-1/4 flex items-center md:flex-col md:items-end">
                            <button
                                onClick={() => setToggle(false)}
                                className="w-1/2 bg-black rounded-full text-white pl pr pt-1 pb-1 font-semibold md:align-right"
                            >
                                <FormattedMessage
                                    id="report.cancel"
                                    values={{ number: 1 }}
                                />
                            </button>
                        </div>
                    </div>
                </div>
            </IntlProvider>
        </section>
    );
};

export default ReportForm;
