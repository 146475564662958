/* eslint-disable */
// import React, { useState, useEffect, useRef } from "react";
// import Map, { Marker, Source, Layer } from "react-map-gl";
// import "mapbox-gl/dist/mapbox-gl.css";
// import { FeatureCollection } from "geojson";

// import { FormattedMessage, IntlProvider } from "react-intl";

// import messages_en from "./translations/en.json";
// import messages_fr from "./translations/fr.json";

// import { FiMapPin, FiFlag } from "react-icons/fi";
// import LocationPointer from "./LocationPointer.tsx"

// const messages = {
//     en: messages_en,
//     fr: messages_fr,
// };

// const containerStyle = {
//     width: "1600px",
// };

// // SVG path for FiLocation (for custom marker)

// //eslint-disable-next-line

// const TOKEN =
//     "pk.eyJ1IjoiY2xhaXJlLW15d2F5IiwiYSI6ImNtNDcyNGU1aTAweGMybHF4N2VlNjFjMGMifQ.Twp9uIEyJryyDWmewAHDMQ";

// const RoutingMap = ({ cards, selectedPoint }) => {
//     let test = 0;
//     const calculateCenter = (cards) => {
//         if (cards && cards.length > 0) {
//             return {
//                 latitude:
//                     cards.reduce((sum, card) => sum + card.location.lat, 0) /
//                     cards.length,
//                 longitude:
//                     cards.reduce((sum, card) => sum + card.location.lng, 0) /
//                     cards.length,
//                 zoom: 13,
//             };
//         }
//         return {
//             latitude: 48.8608311,
//             longitude: 2.3411145,
//             zoom: 5.5,
//         };
//     };

//     const [viewport, setViewport] = useState(calculateCenter(cards));
//     const [optimizedRoute, setOptimizedRoute] = useState(null);
//     const [locale, setLocale] = useState();
//     const isUserInteraction = useRef(false);

//     let directionsService;

//     const online = true;

//     useEffect(() => {
//         if (!isUserInteraction.current) {
//             setViewport(calculateCenter(cards));
//         }
//         isUserInteraction.current = false;

//         const fetchRoute = async () => {
//             try {
//                 if (!cards || cards.length < 2) {
//                     setOptimizedRoute(nothing);
//                     return;
//                 }

//                 const coordinates = cards
//                     .map((card) => `${card.location.lng},${card.location.lat}`)
//                     .join(";");

//                 const url = `https://api.mapbox.com/directions/v5/mapbox/driving/${coordinates}?geometries=geojson&access_token=${TOKEN}&steps=true&overview=full`;

//                 const query = await fetch(url);
//                 const response = await query.json();

//                 if (response.code !== "Ok") {
//                     console.error("Routing error:", response.message);
//                     return;
//                 }

//                 const geometry = response.routes[0].geometry;
//                 const routeGeoJSON = {
//                     type: "FeatureCollection",
//                     features: [
//                         {
//                             type: "Feature",
//                             geometry: geometry,
//                             properties: {},
//                         },
//                     ],
//                 };

//                 setOptimizedRoute(routeGeoJSON);
//             } catch (error) {
//                 console.error("Error fetching route:", error);
//             }
//         };

//         if (!localStorage.getItem("language")) {
//             console.log("No language");
//             fetchUserData();
//         } else {
//             setLocale(localStorage.getItem("language"));
//         }

//         fetchRoute();
//     }, [cards]);

//     // Create an empty GeoJSON feature collection, which will be used as the data source for the route before users add any new data
//     const keepTrack = [];
//     const pointHopper = {};

//     const nothing = {
//         type: "FeatureCollection",
//         features: [],
//     };

//     const layerStyle = {
//         id: "routeline-active",
//         type: "line",
//         paint: {
//             "line-color": "black",
//             "line-width": ["interpolate", ["linear"], ["zoom"], 12, 3, 22, 12],
//         },
//     };

//     // map.addSource('route', {
//     //     type: 'geojson',
//     //     data: nothing
//     //   });

//     // building request for optimization API

//     return (
//         <IntlProvider locale={locale} messages={messages[locale]}>
//             <div className="flex w-3/5 xl:w-2/3 hidden md:flex">
//                 {online && (
//                     <Map
//                         mapboxAccessToken={TOKEN}
//                         {...viewport}
//                         onMove={evt => {
//                             isUserInteraction.current = true;
//                             setViewport(evt.viewState);
//                         }}
//                         style={{ width: "100%", height: "100%" }}
//                         mapStyle="mapbox://styles/mapbox/streets-v9"
//                         interactive={true}
//                     >
//                         <Source type="geojson" data={optimizedRoute || nothing}>
//                             <Layer {...layerStyle} />
//                         </Source>
//                         {cards.map((card, index) => {
//                             const zoomLevel = viewport.zoom
//                             const markerSize = Math.max(zoomLevel * 3, 20)
//                             return <Marker
//                                 key={index}
//                                 longitude={card.location.lng}
//                                 latitude={card.location.lat}
//                                 anchor="bottom"
//                             >
//                                 <LocationPointer isVisited={card.is_visited} isSelected={selectedPoint === card.meeting_id} markerSize = {markerSize} isFirst={index === 0} isLast={index === cards.length - 1}/>
//                             </Marker>
//                         })}
//                     </Map>
//                 )}
//                 {!online && (
//                     <div className="flex flex-row items-center w-full">
//                         <div className="flex flex-col items-center w-full">
//                             <div>You are not online</div>
//                         </div>
//                     </div>
//                 )}
//             </div>
//         </IntlProvider>
//     );
// };

// export default React.memo(RoutingMap);

import React, { useState, useEffect, useRef } from "react";
import Map, { Marker, Source, Layer } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { FeatureCollection } from "geojson";
import { FormattedMessage, IntlProvider } from "react-intl";

import messages_en from "./translations/en.json";
import messages_fr from "./translations/fr.json";
import LocationPointer from "./LocationPointer.tsx";

const messages = {
    en: messages_en,
    fr: messages_fr,
};

const containerStyle = {
    width: "1600px",
};

// SVG path for FiLocation (for custom marker)
// TO REMOVE : add token to different environments parameters
const TOKEN =
    "pk.eyJ1IjoidG9iaWFzLWhvbGxlciIsImEiOiJjbTY3MTFucHYwMDkwMm1yMzdocnVwM3FhIn0.UeC7lZgHPEfFQyXpXSC8RQ";

const RoutingMap = ({ cards, selectedPoint }) => {
    const calculateCenter = (cards) => {
        if (cards && cards.length > 0) {
            const validCards = cards.filter(
                (card) =>
                    card.location &&
                    typeof card.location.lat === "number" &&
                    typeof card.location.lng === "number"
            );

            if (validCards.length > 0) {
                const latitude =
                    validCards.reduce(
                        (sum, card) => sum + card.location.lat,
                        0
                    ) / validCards.length;
                const longitude =
                    validCards.reduce(
                        (sum, card) => sum + card.location.lng,
                        0
                    ) / validCards.length;
                return {
                    latitude,
                    longitude,
                    zoom: 13,
                };
            }
        }

        // Default coordinates when no valid latitude/longitude found
        return {
            latitude: 48.8608311, // Default to Paris coordinates
            longitude: 2.3411145,
            zoom: 5,
        };
    };

    const [viewport, setViewport] = useState(calculateCenter(cards));
    const [optimizedRoute, setOptimizedRoute] = useState(null);
    const [locale, setLocale] = useState();
    const isUserInteraction = useRef(false);

    const online = navigator.onLine; // Check if the user is online

    useEffect(() => {
        if (!isUserInteraction.current) {
            setViewport(calculateCenter(cards));
        }
        isUserInteraction.current = false;

        const fetchRoute = async () => {
            try {
                if (!cards || cards.length < 2) {
                    setOptimizedRoute(nothing);
                    return;
                }

                const coordinates = cards
                    .map((card) => `${card.location.lng},${card.location.lat}`)
                    .join(";");

                const url = `https://api.mapbox.com/directions/v5/mapbox/driving/${coordinates}?geometries=geojson&access_token=${TOKEN}&steps=true&overview=full`;

                const query = await fetch(url);
                const response = await query.json();

                if (response.code !== "Ok") {
                    console.error("Routing error:", response.message);
                    return;
                }

                const geometry = response.routes[0].geometry;
                const routeGeoJSON = {
                    type: "FeatureCollection",
                    features: [
                        {
                            type: "Feature",
                            geometry: geometry,
                            properties: {},
                        },
                    ],
                };

                setOptimizedRoute(routeGeoJSON);
            } catch (error) {
                console.error("Error fetching route:", error);
            }
        };

        if (!localStorage.getItem("language")) {
            console.log("No language");
            fetchUserData();
        } else {
            setLocale(localStorage.getItem("language"));
        }

        fetchRoute();
    }, [cards]);

    // Create an empty GeoJSON feature collection, which will be used as the data source for the route before users add any new data
    const keepTrack = [];
    const pointHopper = {};

    const nothing = {
        type: "FeatureCollection",
        features: [],
    };

    const layerStyle = {
        id: "routeline-active",
        type: "line",
        paint: {
            "line-color": "black",
            "line-width": ["interpolate", ["linear"], ["zoom"], 12, 3, 22, 12],
        },
    };

    return (
        <IntlProvider locale={locale} messages={messages[locale]}>
            <div className="flex w-3/5 xl:w-2/3 hidden md:flex">
                <Map
                    mapboxAccessToken={TOKEN}
                    {...viewport}
                    onMove={(evt) => {
                        isUserInteraction.current = true;
                        setViewport(evt.viewState);
                    }}
                    style={{ width: "100%", height: "100%" }}
                    mapStyle="mapbox://styles/mapbox/streets-v9"
                    interactive={true}
                    renderWorldCopies={false}

                    // Enable service worker caching for offline use
                >
                    <Source type="geojson" data={optimizedRoute || nothing}>
                        <Layer {...layerStyle} />
                    </Source>
                    {cards.map((card, index) => {
                        const zoomLevel = viewport.zoom;
                        const markerSize = Math.max(zoomLevel * 3, 20);
                        return (
                            <Marker
                                key={index}
                                longitude={card.location.lng}
                                latitude={card.location.lat}
                                anchor="bottom"
                            >
                                <LocationPointer
                                    isVisited={card.is_visited}
                                    isSelected={
                                        selectedPoint === card.meeting_id
                                    }
                                    markerSize={markerSize}
                                    isFirst={index === 0}
                                    isLast={index === cards.length - 1}
                                />
                            </Marker>
                        );
                    })}
                </Map>
            </div>
        </IntlProvider>
    );
};

export default React.memo(RoutingMap);
