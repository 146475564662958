import React, { useState, useEffect } from "react";
// import { Route, Routes } from "react-router-dom"; // Import Router components

/*eslint-disable*/

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Bar, Doughnut } from "react-chartjs-2";

import { FiInfo, FiCheckSquare } from "react-icons/fi";

import OfflineMessage from "../../components/offline_message";

ChartJS.register(
    CategoryScale,
    LinearScale,
    LineElement,
    BarElement,
    ArcElement,
    PointElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            display: false,
            position: "top",
        },
        title: {
            display: false,
            text: "Chart.js Bar Chart",
        },
    },
};

import { FormattedMessage, IntlProvider } from "react-intl";

import messages_en from "./translations/en.json";
import messages_pt from "./translations/pt.json";
import messages_fr from "./translations/fr.json";
import SmallStatisticsBox from "../../components/Charts/SmallStatisticsBox";

const messages = {
    en: messages_en,
    pt: messages_pt,
    fr: messages_fr,
};

// Utilities and configurations
import FastAPIClient from "../../client";
import config from "../../config";
import Reports from "./subpages/reports";
import Dashboard from "./subpages/dashboard";
import SalesReps from "./subpages/salesreps";

const client = new FastAPIClient(config);

const useNetworkStatus = () => {
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [isSlowConnection, setIsSlowConnection] = useState(false);

    useEffect(() => {
        const handleOnline = () => setIsOnline(true);
        const handleOffline = () => setIsOnline(false);

        const handleConnectionChange = () => {
            const connection =
                navigator.connection ||
                navigator.mozConnection ||
                navigator.webkitConnection;
            if (connection) {
                setIsSlowConnection(
                    connection.effectiveType === "2g" ||
                        connection.effectiveType === "3g" ||
                        connection.downlink < 1.5
                );
            }
        };

        window.addEventListener("online", handleOnline);
        window.addEventListener("offline", handleOffline);

        if (navigator.connection) {
            handleConnectionChange();
            navigator.connection.addEventListener(
                "change",
                handleConnectionChange
            );
        }

        return () => {
            window.removeEventListener("online", handleOnline);
            window.removeEventListener("offline", handleOffline);

            if (navigator.connection) {
                navigator.connection.removeEventListener(
                    "change",
                    handleConnectionChange
                );
            }
        };
    }, []);

    return { isOnline, isSlowConnection };
};

const ManagementDashboard = () => {
    const { isOnline, isSlowConnection } = useNetworkStatus();

    const [locale, setLocale] = useState();

    useEffect(() => {
        console.log("Online", isOnline);
        if (isOnline && !isSlowConnection) {
            setLocale(localStorage.getItem("language"));
        }
    }, [isOnline, isSlowConnection]);

    const SubNavigation = ({ navigate, currentPath }) => {
        const getButtonClass = (path) => {
            return currentPath === path
                ? "font-bold text-black border-b-2 border-black "
                : "text-black";
        };

        return (
            <div className="sub-nav border-b border-gray-100 ml-20 mb-5">
                <ul className="flex space-x-4">
                    <li>
                        <button
                            onClick={() => navigate("/management/dashboard")}
                            className={getButtonClass("/management/dashboard")}
                        >
                            Tableau de bord
                        </button>
                    </li>
                    <li>
                        <button
                            onClick={() => navigate("/management/reports")}
                            className={getButtonClass("/management/reports")}
                        >
                            Comptes rendu
                        </button>
                    </li>
                    <li>
                        <button
                            onClick={() => navigate("/management/salesreps")}
                            className={getButtonClass("/management/salesreps")}
                        >
                            Commerciaux
                        </button>
                    </li>
                </ul>
            </div>
        );
    };

    const [currentPath, setCurrentPath] = useState(window.location.pathname);

    // Function to handle navigation and URL update
    const navigate = (path) => {
        window.history.pushState({}, "", path);
        setCurrentPath(path); // Trigger re-render by updating the state
    };

    // Listen for popstate events (back/forward buttons)
    useEffect(() => {
        const handlePopState = () => {
            setCurrentPath(window.location.pathname);
        };

        window.addEventListener("popstate", handlePopState);

        return () => {
            window.removeEventListener("popstate", handlePopState);
        };
    }, []);

    const currentDate = new Date();
    const formattedDate = `${
        currentDate.getMonth() + 1
    }.${currentDate.getDate()}.${currentDate.getFullYear()}`;

    const [successMessage, setSuccessMessage] = useState(""); // Track success message
    useEffect(() => {
        if (successMessage) {
            const timer = setTimeout(() => setSuccessMessage(""), 3000); // Hide message after 3 seconds
            return () => clearTimeout(timer); // Cleanup on unmount
        }
    }, [successMessage]);

    const [errorMessage, setErrorMessage] = useState(""); // Track success message
    useEffect(() => {
        if (errorMessage) {
            const timer = setTimeout(() => setErrorMessage(""), 3000); // Hide message after 3 seconds
            return () => clearTimeout(timer); // Cleanup on unmount
        }
    }, [errorMessage]);

    // Conditionally render content based on the current path
    let content;
    if (currentPath === "/management/dashboard") {
        content = <Dashboard />;
    } else if (currentPath === "/management/reports") {
        content = <Reports />;
    } else if (currentPath === "/management/salesreps") {
        content = (
            <SalesReps
                setSuccessMessage={setSuccessMessage}
                setErrorMessage={setErrorMessage}
            />
        );
    } else {
        content = <div>404 Not Found</div>;
    }

    if (!isOnline) {
        return <OfflineMessage />;
    }
    if (isOnline) {
        return (
            <>
                <IntlProvider locale={locale} messages={messages[locale]}>
                    <div className="mt-10 ml-5 md:mt-20 text-left md:ml-20 flex flex-row items-center">
                        <h1 className="mb-12 text-xl text-black mt-5">
                            <FormattedMessage
                                id="performance.my_statistics"
                                values={{ number: 1 }}
                            />
                        </h1>
                        <h1 className="mb-12 text-sm text-gray-400 mt-6 ml-5 ">
                            <FormattedMessage
                                id="performance.last_updated"
                                values={{ number: 1 }}
                            />
                            {formattedDate}
                        </h1>
                    </div>
                    <SubNavigation
                        navigate={navigate}
                        currentPath={currentPath}
                    />

                    <div>{content}</div>
                    {successMessage && (
                        <div className="fixed bottom-0 left-0 right-0 bg-green-500 text-white text-center py-2 z-50 mx-auto w-1/5 rounded-t-lg">
                            {successMessage}
                        </div>
                    )}
                    {errorMessage && (
                        <div className="fixed bottom-0 left-0 right-0 bg-rose-500 text-white text-center py-2 z-50 mx-auto w-1/5 rounded-t-lg">
                            {errorMessage}
                        </div>
                    )}
                </IntlProvider>
            </>
        );
    }
};

export default ManagementDashboard;
