import React, { useState, useEffect } from "react";

/*eslint-disable*/

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    LineElement,
    PointElement,
    BarElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Bar, Doughnut } from "react-chartjs-2";

import { FiInfo, FiCheckSquare } from "react-icons/fi";

ChartJS.register(
    CategoryScale,
    LinearScale,
    LineElement,
    BarElement,
    ArcElement,
    PointElement,
    Title,
    Tooltip,
    Legend
);

import OfflineMessage from "../../components/offline_message";

export const options = {
    responsive: true,
    plugins: {
        legend: {
            display: false,
            position: "top",
        },
        title: {
            display: false,
            text: "Chart.js Bar Chart",
        },
    },
};

import { FormattedMessage, IntlProvider } from "react-intl";

import messages_en from "./translations/en.json";
import messages_pt from "./translations/pt.json";
import messages_fr from "./translations/fr.json";
import SmallStatisticsBox from "../../components/Charts/SmallStatisticsBox";

const messages = {
    en: messages_en,
    pt: messages_pt,
    fr: messages_fr,
};

// Utilities and configurations
import FastAPIClient from "../../client";
import config from "../../config";

const client = new FastAPIClient(config);

const useNetworkStatus = () => {
    const [isOnline, setIsOnline] = useState(navigator.onLine);
    const [isSlowConnection, setIsSlowConnection] = useState(false);

    useEffect(() => {
        const handleOnline = () => setIsOnline(true);
        const handleOffline = () => setIsOnline(false);

        const handleConnectionChange = () => {
            const connection =
                navigator.connection ||
                navigator.mozConnection ||
                navigator.webkitConnection;
            if (connection) {
                setIsSlowConnection(
                    connection.effectiveType === "2g" ||
                        connection.effectiveType === "3g" ||
                        connection.downlink < 1.5
                );
            }
        };

        window.addEventListener("online", handleOnline);
        window.addEventListener("offline", handleOffline);

        if (navigator.connection) {
            handleConnectionChange();
            navigator.connection.addEventListener(
                "change",
                handleConnectionChange
            );
        }

        return () => {
            window.removeEventListener("online", handleOnline);
            window.removeEventListener("offline", handleOffline);

            if (navigator.connection) {
                navigator.connection.removeEventListener(
                    "change",
                    handleConnectionChange
                );
            }
        };
    }, []);

    return { isOnline, isSlowConnection };
};

const Profile = () => {
    // if (refreshing) return !isLoggedIn ? <NotLoggedIn /> : <Loader />;

    const { isOnline, isSlowConnection } = useNetworkStatus();

    const [locale, setLocale] = useState();

    const [chartData, setChartData] = useState([]);
    const [monthly_revenue_chart, setMonthlyRevenueChart] = useState([]);
    const [monthly_volume_chart, setMonthlyVolumeChart] = useState([]);
    const [doughnut_chart, setDoughnutChart] = useState([]);
    const [product_doughnut_chart, setProductDoughnutChart] = useState([]);
    const [annual_comparison, setAnnualComparison] = useState([]);
    const [userName, setUsername] = useState([]);

    useEffect(() => {
        // setRefreshing(false);
        if (isOnline && !isSlowConnection) {
            setLocale(localStorage.getItem("language"));
            getUserName();
        }
    }, [isOnline, isSlowConnection]);

    // useEffect(() => {
    //     console.log("la", monthly_revenue_chart);
    //     console.log("lu", chartData);
    //     console.log("lo", annual_comparison);
    // }, [monthly_revenue_chart]);

    const getUserName = () => {
        client
            .fetchUser()
            .then((data) => {
                // Set the username in the state
                console.log(data);
                setUsername(data);
                // Call fetchChartData and pass the user_id after fetching the user
                if (data && data.user_id) {
                    fetchChartData(data.user_id); // Inject user_id into fetchChartData
                }
            })
            .catch((error) => {
                console.error("Error fetching user:", error);
            });
    };

    const fetchChartData = (userId) => {
        // Ensure we have a userId before calling getUserStats
        if (!userId) {
            console.error("User ID is required to fetch stats");
            return;
        }

        client
            .getUserStats(userId)
            .then((data) => {
                // Set the charts and other data from the response
                console.log(data);
                setMonthlyRevenueChart(
                    data.results.filter(
                        (item) => item.metric_name === "monthly_revenue_summary"
                    )[0].json_data
                );
                setMonthlyVolumeChart(
                    data.results.filter(
                        (item) => item.metric_name === "monthly_volume_summary"
                    )[0].json_data
                );
                setDoughnutChart(
                    data.results.filter(
                        (item) => item.metric_name === "doughnut_data"
                    )[0].json_data
                );
                setProductDoughnutChart(
                    data.results.filter(
                        (item) => item.metric_name === "product_doughnut_data"
                    )[0].json_data
                );
                setAnnualComparison(
                    data.results.filter(
                        (item) => item.metric_name === "annual_comparison"
                    )
                );
                console.log(
                    data.results.filter(
                        (item) => item.metric_name === "monthly_revenue_summary"
                    )[0].json_data
                );
                console.log(data.results);
                setChartData(data.results);
            })
            .catch((error) => {
                console.error("Error fetching user stats:", error);
            });
    };

    // Function to generate gradient colors between white and black
    // Function to generate a gradient between two colors
    function generateGradientColors(startColor, endColor, numColors) {
        const start = {
            r: parseInt(startColor.slice(1, 3), 16),
            g: parseInt(startColor.slice(3, 5), 16),
            b: parseInt(startColor.slice(5, 7), 16),
        };
        const end = {
            r: parseInt(endColor.slice(1, 3), 16),
            g: parseInt(endColor.slice(3, 5), 16),
            b: parseInt(endColor.slice(5, 7), 16),
        };

        const colors = [];
        for (let i = 0; i < numColors; i++) {
            const ratio = i / (numColors - 1); // Calculate ratio between 0 and 1
            const r = Math.round(start.r + ratio * (end.r - start.r));
            const g = Math.round(start.g + ratio * (end.g - start.g));
            const b = Math.round(start.b + ratio * (end.b - start.b));
            colors.push(`rgb(${r}, ${g}, ${b})`);
        }
        return colors;
    }

    // Update doughnut data dynamically based on labels length
    const doughnut_data = {
        labels: ["Restaurant", "Café", "Bar", "Discotheque"], // Add labels as needed
        datasets: [
            {
                data: [300, 50, 100, 200], // Corresponding data values
                backgroundColor: generateGradientColors(
                    "#2D3F04",
                    "#C3FF36",
                    ["Restaurant", "Café", "Bar", "Discotheque"].length
                ), // Generate colors based on label count
            },
        ],
    };

    const doughnut_options = {
        plugins: {
            legend: {
                display: true,
                position: "right",
                text: "Client type",
            },
        },
        elements: {
            arc: {
                borderWidth: 0,
            },
        },
        cutout: "70%", // Adjust this percentage to make the arcs thinner or thicker
    };

    const stacked_options = {
        plugins: {
            legend: {
                position: "right",
                display: false,
            },
            title: {
                display: false,
                text: "Chart.js Bar Chart - Stacked",
            },
        },
        responsive: true,
        scales: {
            x: {
                stacked: true,
                ticks: {
                    color: "black",
                },
                grid: {
                    display: false,
                },
            },
            y: {
                stacked: true,
                ticks: {
                    color: "black",
                },
                grid: {
                    display: true, // Show horizontal grid lines
                    color: "rgba(0, 0, 0, 0.1)", // Light gray color for subtle lines
                    lineWidth: 1, // Customize line thickness
                    borderDash: [5, 5], // Optional: dashed lines for a lighter look
                },
            },
        },
        maintainAspectRatio: false, // Allow control over width and height
    };
    const nba_result_data = {
        labels: ["En attente", "Acceptée", "Refusé"],
        datasets: [
            {
                label: "beer",
                data: [450, 700, 250], // Example data
                backgroundColor: ["#000000", "#C3FF36", "#FF365E"], // Colors for each label
                borderRadius: 30,
                barThickness: 20, // Set to a smaller value for thinner bars
            },
        ],
    };

    const nba_type_data = {
        labels: [
            "Prévention départ des clients",
            "Recommandation produit",
            "Gestion des relations",
            "Optimisation du cycle de commande",
        ],
        datasets: [
            {
                label: "beer",
                data: [700, 450, 250, 200], // Example data
                backgroundColor: ["#000000", "#000000", "#000000", "#000000"], // Colors for each label
                borderRadius: 30,
                barThickness: 20, // Set to a smaller value for thinner bars
            },
        ],
    };

    const currentDate = new Date();
    const formattedDate = `${
        currentDate.getMonth() + 1
    }.${currentDate.getDate()}.${currentDate.getFullYear()}`;

    if (!isOnline) {
        return <OfflineMessage />;
    }
    if (isOnline) {
        return (
            <>
                <IntlProvider locale={locale} messages={messages[locale]}>
                    <div className="mt-10 ml-5 md:mt-20 text-left md:ml-20 flex flex-row items-center">
                        <h1 className="mb-12 text-xl text-black mt-5">
                            <FormattedMessage
                                id="performance.my_statistics"
                                values={{ number: 1 }}
                            />
                        </h1>
                        <h1 className="mb-12 text-sm text-gray-400 mt-6 ml-5 ">
                            <FormattedMessage
                                id="performance.last_updated"
                                values={{ number: 1 }}
                            />
                            {formattedDate}
                        </h1>
                    </div>
                    <div className="flex flex-col ml-5 mr-5 md:mr-0 md:ml-0 md:flex-row pb-6 mb-20 text-black rounded-3xl">
                        <div className=" container flex flex-col bg-white rounded-3xl md:ml-10 mr-10 w-full">
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-2 align-start md:ml-10">
                                {annual_comparison &&
                                    annual_comparison.map((stat, index) => (
                                        <SmallStatisticsBox
                                            key={index} // Or use a unique identifier if available
                                            name={stat.json_data.name}
                                            value_current_year={
                                                stat.json_data
                                                    .value_current_year
                                            }
                                            change={stat.json_data.change}
                                            value_previous_year={
                                                stat.json_data
                                                    .value_previous_year
                                            }
                                            period={"annual"}
                                        />
                                    ))}
                                <SmallStatisticsBox
                                    key={"A"} // Or use a unique identifier if available
                                    name={"Taux de couverture"}
                                    value_current_year={"0%"}
                                    change={"0%"}
                                    value_previous_year={"0%"}
                                    period={"annual"}
                                />
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-2 align-start md:ml-10">
                                <div className="p-4 bg-white rounded-md border border-gray-200 w-full text-left">
                                    <h3 className="text-black text-lg ">
                                        <FormattedMessage
                                            id="performance.sales_by_product_type"
                                            values={{ number: 1 }}
                                        />
                                    </h3>
                                    <div
                                        style={{
                                            minHeight: 250,
                                            maxWidth: 650,
                                        }}
                                        className="mb-5 mt-5"
                                    >
                                        {monthly_revenue_chart.labels &&
                                            (console.log(
                                                "thats the data",
                                                monthly_revenue_chart
                                            ),
                                            (
                                                <Bar
                                                    options={stacked_options}
                                                    data={monthly_revenue_chart}
                                                />
                                            ))}
                                    </div>
                                </div>
                                <div className="p-4 bg-white rounded-md border border-gray-200 w-full text-left">
                                    <h3 className="text-black text-lg">
                                        Volume (hl)
                                    </h3>
                                    <div
                                        style={{
                                            minHeight: 250,
                                            maxWidth: 650,
                                        }}
                                        className="mb-5 mt-5"
                                    >
                                        {monthly_volume_chart.labels &&
                                            (console.log(
                                                "thats the data",
                                                monthly_volume_chart
                                            ),
                                            (
                                                <Bar
                                                    options={stacked_options}
                                                    data={monthly_volume_chart}
                                                />
                                            ))}
                                    </div>
                                </div>
                            </div>
                            <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-2 align-start md:ml-10 mt-4">
                                <div className="p-4 bg-white rounded-md border border-gray-200 w-full text-left">
                                    <h3 className="text-black text-lg ">
                                        <FormattedMessage
                                            id="performance.client_by_type"
                                            values={{ number: 1 }}
                                        />
                                    </h3>
                                    <div
                                        className="flex justify-center items-center"
                                        style={{
                                            maxWidth: 300,
                                            margin: "0 auto",
                                        }}
                                    >
                                        {doughnut_chart.labels &&
                                            (console.log(
                                                "thats the data",
                                                monthly_revenue_chart
                                            ),
                                            (
                                                <Doughnut
                                                    options={doughnut_options}
                                                    data={doughnut_chart}
                                                />
                                            ))}
                                    </div>
                                </div>
                                <div className="p-4 bg-white rounded-md border border-gray-200 w-full text-left">
                                    <h3 className="text-black text-lg">
                                        Répartition par catégorie produits (€)
                                    </h3>
                                    <div
                                        className="flex justify-center items-center"
                                        style={{
                                            maxWidth: 300,
                                            margin: "0 auto",
                                        }}
                                    >
                                        {doughnut_chart.labels &&
                                            (console.log(
                                                "thats the data",
                                                monthly_revenue_chart
                                            ),
                                            (
                                                <Doughnut
                                                    options={doughnut_options}
                                                    data={
                                                        product_doughnut_chart
                                                    }
                                                />
                                            ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </IntlProvider>
            </>
        );
    }
};

export default Profile;
