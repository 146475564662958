import React, { useState } from "react";

import { IntlProvider } from "react-intl";

import messages_en from "./translations/en.json";
import messages_fr from "./translations/fr.json";
import { FiPercent, FiShoppingBag } from "react-icons/fi";
import { GiThreeLeaves } from "react-icons/gi";
import ProductModal from "../../ModalComponents/ProductModal";

const messages = {
    en: messages_en,
    fr: messages_fr,
};

// import PopupModalSmall from "../../components/ModalSmall/PopupModalSmall";

const ProductDetails = ({ product, locale }) => {
    const [showModal, setShowModal] = useState(false);

    return (
        product && (
            <>
                <IntlProvider locale={locale} messages={messages[locale]}>
                    {console.log(product)}
                    <div
                        className="flex w-full rounded-lg cursor-pointer"
                        onClick={() => setShowModal(true)}
                    >
                        <div className="flex flex-col items-center rounded-lg border border-grey-200 w-full bg-white">
                            {/* Image Container */}
                            <div className="relative w-1/2 mt-2">
                                <img
                                    className="w-full object-contain pl-2 pr-2"
                                    src={product?.image}
                                    alt="Product"
                                />

                                {/* Promotion Badge - Fully Inside the Image */}
                                {product?.promotion && (
                                    <div className="absolute right-0 top-4 bg-rose-500 rounded-full text-white px-2 py-2 text-base">
                                        <FiPercent />
                                    </div>
                                )}
                                {product?.organic === "true" && (
                                    <div className="absolute right-0 top-4 bg-green-500 rounded-full text-white px-2 py-2 text-base">
                                        <GiThreeLeaves />
                                    </div>
                                )}
                            </div>

                            {/* Product Details */}
                            <div className="flex flex-col w-3/4 h-1/2 text-xs">
                                <div className="flex flex-row items-center mb-2 pr-2">
                                    <p className="text-base text-black font-semibold">
                                        {product?.short_label}
                                    </p>
                                </div>

                                <div className="flex flex-row items-center text-gray-600 text-sm mr-4">
                                    Réf: {product?.product_id}
                                </div>
                                <div className="flex flex-row items-center text-gray-600 text-sm mr-4">
                                    {product?.packaging_code}
                                </div>

                                {/* Buy Button */}
                            </div>
                            <div className="flex items-center justify-center md:justify-end mb-2 w-full mt-5 md:mr-5">
                                <button
                                    className="flex flex-row items-center justify-center md:ml-10 bg-white border border-black text-black rounded-full md:px-2 md:py-1 text-base md:w-1/3 w-4/5"
                                    onClick={() =>
                                        window.open(product?.shop_url, "_blank")
                                    }
                                >
                                    <span>
                                        <FiShoppingBag />
                                    </span>
                                    <div className="ml-2">Acheter</div>
                                </button>
                            </div>
                            <ProductModal
                                showModal={showModal}
                                setShowModal={setShowModal}
                                product={product}
                            />
                        </div>
                    </div>
                </IntlProvider>
            </>
        )
    );
};

export default ProductDetails;
