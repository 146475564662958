import React from "react";
import { FiWifiOff } from "react-icons/fi";

function OfflineMessage() {
    return (
        <div className="flex flex-col justify-center items-center h-screen w-full bg-white text-center">
            <FiWifiOff size={50} color="#000000" className="mb-4" />
            <p className="text-lg font-semibold text-gray-700">
                Cette page est disponible uniquement lorsque vous êtes en ligne.
            </p>
        </div>
    );
}

export default OfflineMessage;
