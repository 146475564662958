import React, { useEffect, useState } from "react";
import FastAPIClient from "../../client";
import config from "../../config";
import ProductDetails from "../../components/LayoutComponents/ProductDetails";
import Loader from "../../components/Loader";
import "./style.css";

const client = new FastAPIClient(config);

import { FormattedMessage, IntlProvider } from "react-intl";

import messages_en from "./translations/en.json";
import messages_pt from "./translations/pt.json";
import messages_fr from "./translations/fr.json";

import ProductSearch from "../../components/Search/ProductSearch";
import {
    FiChevronDown,
    FiFilter,
    FiPlus,
    FiXCircle,
    FiMinus,
} from "react-icons/fi";

const messages = {
    en: messages_en,
    pt: messages_pt,
    fr: messages_fr,
};

const Products = () => {
    const [products, setProducts] = useState([]);
    const [locale, setLocale] = useState();
    const [refreshing, setRefreshing] = useState(true);
    const [selectedFilters, setSelectedFilters] = useState({
        beer_family: [],
        beer_color: [],
        beer_style: [],
        beer_flavor: [],
        beer_region: [],
        beer_head_type: [],
        beer_bitterness: [],
        gluten_free_beer: [],
        wine_color: [],
        wine_vineyard: [],
        wine_denomination: [],
        wine_appellation: [],
        wine_millesime: [],
        wine_sugar_content: [],
        wine_grape: [],
        spirit_family: [],
        spirit_type: [],
        spirit_millesime: [],
        spirit_age: [],
        spirit_region: [],
        water_family: [],
        soft_family: [],
        juice_family: [],
        sirop_family: [],
        flavour: [],
        coffee_type: [],
        decaf_coffee: [],
        coffee_pod_type: [],
        coffee_roasting_region: [],
        coffee_roast: [],
        coffee_variety: [],
        coffee_ingredients: [],
        coffee_intensity: [],
        coffee_label: [],
        tea_type: [],
        tea_color: [],
        tea_flavor: [],
        tea_label: [],
        hot_beverage_type: [],
        grocery_type: [],
        material_type: [],
        hygiene_type: [],
        cider_type: [],
        cider_head_type: [],
        cider_flavor: [],
        cider_region: [],
        container_format: [],
        manufacturer_brand: [],
        deposit: [],
        country: [],
        product_level_1: [], // <-- Add this line
    });
    // const [expandedLevels, setExpandedLevels] = useState({});

    useEffect(() => {
        const getProducts = async () => {
            client.getAllProducts().then((data) => {
                setProducts(data?.results);
                setLocale(localStorage.getItem("language"));
            });
        };
        getProducts();
    }, []);

    useEffect(() => {
        if (products.length > 0) {
            setRefreshing(false);
        }
    }, [products]);

    const productLevels = Array.from(
        new Set(products.map((prod) => prod.product_level_1))
    );

    // Extract unique values for beer_color, beer_family, etc.
    const extractUniqueValuesWithCount = (property) => {
        // Step 1: Get all unique values from the full product list
        const allUniqueValues = Array.from(
            new Set(products.map((prod) => prod[property]))
        ).filter(Boolean);

        // Step 2: Calculate counts from the filtered product list
        const valueCounts = {};
        filteredProducts.forEach((prod) => {
            const value = prod[property];
            if (value) {
                valueCounts[value] = (valueCounts[value] || 0) + 1;
            }
        });

        // Step 3: Return all options with counts, defaulting to 0 for options not in filteredProducts
        return allUniqueValues.map((value) => [value, valueCounts[value] || 0]);
    };

    // Handle the filter change
    const handleFilterChange = (filterName, value) => {
        setSelectedFilters((prevFilters) => {
            const newFilters = { ...prevFilters };
            const currentFilterValues = newFilters[filterName] || [];
            if (currentFilterValues.includes(value)) {
                newFilters[filterName] = currentFilterValues.filter(
                    (val) => val !== value
                );
            } else {
                newFilters[filterName] = [...currentFilterValues, value];
            }
            return newFilters;
        });
    };

    // Filter products based on the selected filters
    const filteredProducts = products.filter((prod) => {
        let isMatch = true;
        if (selectedFilters.product_level_1.length > 0) {
            isMatch =
                selectedFilters.product_level_1.includes(
                    prod.product_level_1
                ) && isMatch;
        }

        // Beer filters
        if (selectedFilters.beer_family.length > 0) {
            isMatch =
                selectedFilters.beer_family.includes(prod.beer_family) &&
                isMatch;
        }
        if (selectedFilters.beer_color.length > 0) {
            isMatch =
                selectedFilters.beer_color.includes(prod.beer_color) && isMatch;
        }
        if (selectedFilters.beer_style.length > 0) {
            isMatch =
                selectedFilters.beer_style.includes(prod.beer_style) && isMatch;
        }
        if (selectedFilters.beer_region.length > 0) {
            isMatch =
                selectedFilters.beer_region.includes(prod.beer_region) &&
                isMatch;
        }
        if (selectedFilters.beer_head_type.length > 0) {
            isMatch =
                selectedFilters.beer_head_type.includes(prod.beer_head_type) &&
                isMatch;
        }
        if (selectedFilters.beer_flavor.length > 0) {
            isMatch =
                selectedFilters.beer_flavor.includes(prod.beer_flavor) &&
                isMatch;
        }
        if (selectedFilters.beer_bitterness.length > 0) {
            isMatch =
                selectedFilters.beer_bitterness.includes(
                    prod.beer_bitterness
                ) && isMatch;
        }
        if (selectedFilters.gluten_free_beer.length > 0) {
            isMatch =
                selectedFilters.gluten_free_beer.includes(
                    prod.gluten_free_beer
                ) && isMatch;
        }

        // Wine filters - apply only for 'VINS' products

        if (selectedFilters.wine_color.length > 0) {
            isMatch =
                selectedFilters.wine_color.includes(prod.wine_color) && isMatch;
        }
        if (selectedFilters.wine_vineyard.length > 0) {
            isMatch =
                selectedFilters.wine_vineyard.includes(prod.wine_vineyard) &&
                isMatch;
        }
        if (selectedFilters.wine_denomination.length > 0) {
            isMatch =
                selectedFilters.wine_denomination.includes(
                    prod.wine_denomination
                ) && isMatch;
        }
        if (selectedFilters.wine_appellation.length > 0) {
            isMatch =
                selectedFilters.wine_appellation.includes(
                    prod.wine_appellation
                ) && isMatch;
        }
        if (selectedFilters.wine_millesime.length > 0) {
            isMatch =
                selectedFilters.wine_millesime.includes(prod.wine_millesime) &&
                isMatch;
        }
        if (selectedFilters.wine_sugar_content.length > 0) {
            isMatch =
                selectedFilters.wine_sugar_content.includes(
                    prod.wine_sugar_content
                ) && isMatch;
        }
        if (selectedFilters.wine_grape.length > 0) {
            isMatch =
                selectedFilters.wine_grape.includes(prod.wine_grape) && isMatch;
        }
        if (selectedFilters.spirit_family.length > 0) {
            isMatch =
                selectedFilters.spirit_family.includes(prod.spirit_family) &&
                isMatch;
        }
        if (selectedFilters.spirit_type.length > 0) {
            isMatch =
                selectedFilters.spirit_type.includes(prod.spirit_type) &&
                isMatch;
        }
        if (selectedFilters.spirit_millesime.length > 0) {
            isMatch =
                selectedFilters.spirit_millesime.includes(
                    prod.spirit_millesime
                ) && isMatch;
        }
        if (selectedFilters.spirit_age.length > 0) {
            isMatch =
                selectedFilters.spirit_age.includes(prod.spirit_age) && isMatch;
        }
        if (selectedFilters.spirit_region.length > 0) {
            isMatch =
                selectedFilters.spirit_region.includes(prod.spirit_region) &&
                isMatch;
        }
        if (selectedFilters.water_family.length > 0) {
            isMatch =
                selectedFilters.water_family.includes(prod.water_family) &&
                isMatch;
        }
        if (selectedFilters.soft_family.length > 0) {
            isMatch =
                selectedFilters.soft_family.includes(prod.soft_family) &&
                isMatch;
        }
        if (selectedFilters.juice_family.length > 0) {
            isMatch =
                selectedFilters.juice_family.includes(prod.juice_family) &&
                isMatch;
        }
        if (selectedFilters.sirop_family.length > 0) {
            isMatch =
                selectedFilters.sirop_family.includes(prod.sirop_family) &&
                isMatch;
        }
        if (selectedFilters.flavour.length > 0) {
            isMatch = selectedFilters.flavour.includes(prod.flavour) && isMatch;
        }
        if (selectedFilters.coffee_type.length > 0) {
            isMatch =
                selectedFilters.coffee_type.includes(prod.coffee_type) &&
                isMatch;
        }
        if (selectedFilters.decaf_coffee.length > 0) {
            isMatch =
                selectedFilters.decaf_coffee.includes(prod.decaf_coffee) &&
                isMatch;
        }
        if (selectedFilters.coffee_pod_type.length > 0) {
            isMatch =
                selectedFilters.coffee_pod_type.includes(
                    prod.coffee_pod_type
                ) && isMatch;
        }
        if (selectedFilters.coffee_roasting_region.length > 0) {
            isMatch =
                selectedFilters.coffee_roasting_region.includes(
                    prod.coffee_roasting_region
                ) && isMatch;
        }
        if (selectedFilters.coffee_roast.length > 0) {
            isMatch =
                selectedFilters.coffee_roast.includes(prod.coffee_roast) &&
                isMatch;
        }
        if (selectedFilters.coffee_variety.length > 0) {
            isMatch =
                selectedFilters.coffee_variety.includes(prod.coffee_variety) &&
                isMatch;
        }
        if (selectedFilters.coffee_ingredients.length > 0) {
            isMatch =
                selectedFilters.coffee_ingredients.includes(
                    prod.coffee_ingredients
                ) && isMatch;
        }
        if (selectedFilters.coffee_intensity.length > 0) {
            isMatch =
                selectedFilters.coffee_intensity.includes(
                    prod.coffee_intensity
                ) && isMatch;
        }
        if (selectedFilters.coffee_label.length > 0) {
            isMatch =
                selectedFilters.coffee_label.includes(prod.coffee_label) &&
                isMatch;
        }
        if (selectedFilters.tea_type.length > 0) {
            isMatch =
                selectedFilters.tea_type.includes(prod.tea_type) && isMatch;
        }
        if (selectedFilters.tea_color.length > 0) {
            isMatch =
                selectedFilters.tea_color.includes(prod.tea_color) && isMatch;
        }
        if (selectedFilters.tea_flavor.length > 0) {
            isMatch =
                selectedFilters.tea_flavor.includes(prod.tea_flavor) && isMatch;
        }
        if (selectedFilters.tea_label.length > 0) {
            isMatch =
                selectedFilters.tea_label.includes(prod.tea_label) && isMatch;
        }
        if (selectedFilters.hot_beverage_type.length > 0) {
            isMatch =
                selectedFilters.hot_beverage_type.includes(
                    prod.hot_beverage_type
                ) && isMatch;
        }
        if (selectedFilters.grocery_type.length > 0) {
            isMatch =
                selectedFilters.grocery_type.includes(prod.grocery_type) &&
                isMatch;
        }
        if (selectedFilters.material_type.length > 0) {
            isMatch =
                selectedFilters.material_type.includes(prod.material_type) &&
                isMatch;
        }
        if (selectedFilters.hygiene_type.length > 0) {
            isMatch =
                selectedFilters.hygiene_type.includes(prod.hygiene_type) &&
                isMatch;
        }
        if (selectedFilters.cider_type.length > 0) {
            isMatch =
                selectedFilters.cider_type.includes(prod.cider_type) && isMatch;
        }
        if (selectedFilters.cider_head_type.length > 0) {
            isMatch =
                selectedFilters.cider_head_type.includes(
                    prod.cider_head_type
                ) && isMatch;
        }
        if (selectedFilters.cider_flavor.length > 0) {
            isMatch =
                selectedFilters.cider_flavor.includes(prod.cider_flavor) &&
                isMatch;
        }
        if (selectedFilters.cider_region.length > 0) {
            isMatch =
                selectedFilters.cider_region.includes(prod.cider_region) &&
                isMatch;
        }
        if (selectedFilters.deposit.length > 0) {
            isMatch = selectedFilters.deposit.includes(prod.deposit) && isMatch;
        }
        if (selectedFilters.country.length > 0) {
            isMatch = selectedFilters.country.includes(prod.country) && isMatch;
        }
        if (selectedFilters.manufacturer_brand.length > 0) {
            isMatch =
                selectedFilters.manufacturer_brand.includes(
                    prod.manufacturer_brand
                ) && isMatch;
        }
        if (selectedFilters.container_format.length > 0) {
            isMatch =
                selectedFilters.container_format.includes(
                    prod.container_format
                ) && isMatch;
        }

        return isMatch;
    });

    const filterMappings = {
        beer_family: {
            en: "Beer Family",
            fr: "Famille de bière",
        },
        beer_color: {
            en: "Beer Color",
            fr: "Couleur de bière",
        },
        beer_style: {
            en: "Beer Style",
            fr: "Style de bière",
        },
        beer_flavor: {
            en: "Beer Flavor",
            fr: "Saveur de bière",
        },
        beer_region: {
            en: "Beer Region",
            fr: "Région de bière",
        },
        beer_head_type: {
            en: "Beer Head Type",
            fr: "Type de mousse",
        },
        beer_bitterness: {
            en: "Beer Bitterness",
            fr: "Amertume de bière",
        },
        gluten_free_beer: {
            en: "Gluten-Free Beer",
            fr: "Bière sans gluten",
        },
        wine_color: {
            en: "Wine Color",
            fr: "Couleur de vin",
        },
        wine_vineyard: {
            en: "Wine Vineyard",
            fr: "Vignoble",
        },
        wine_denomination: {
            en: "Wine Denomination",
            fr: "Dénomination de vin",
        },
        wine_appellation: {
            en: "Wine Appellation",
            fr: "Appellation de vin",
        },
        wine_millesime: {
            en: "Wine Vintage",
            fr: "Millésime de vin",
        },
        wine_sugar_content: {
            en: "Wine Sugar Content",
            fr: "Teneur en sucre de vin",
        },
        wine_grape: {
            en: "Wine Grape",
            fr: "Cépage de vin",
        },
        spirit_family: {
            en: "Spirit Family",
            fr: "Famille de spiritueux",
        },
        spirit_type: {
            en: "Spirit Type",
            fr: "Type de spiritueux",
        },
        spirit_millesime: {
            en: "Spirit Vintage",
            fr: "Millésime de spiritueux",
        },
        spirit_age: {
            en: "Spirit Age",
            fr: "Âge de spiritueux",
        },
        spirit_region: {
            en: "Spirit Region",
            fr: "Région de spiritueux",
        },
        water_family: {
            en: "Water Family",
            fr: "Famille d'eau",
        },
        soft_family: {
            en: "Soft Drink Family",
            fr: "Famille de boissons gazeuses",
        },
        juice_family: {
            en: "Juice Family",
            fr: "Famille de jus",
        },
        sirop_family: {
            en: "Syrup Family",
            fr: "Famille de sirops",
        },
        flavour: {
            en: "Flavor",
            fr: "Saveur",
        },
        coffee_type: {
            en: "Coffee Type",
            fr: "Type de café",
        },
        decaf_coffee: {
            en: "Decaf Coffee",
            fr: "Café décaféiné",
        },
        coffee_pod_type: {
            en: "Coffee Pod Type",
            fr: "Type de dosette de café",
        },
        coffee_roasting_region: {
            en: "Coffee Roasting Region",
            fr: "Région de torréfaction",
        },
        coffee_roast: {
            en: "Coffee Roast",
            fr: "Torréfaction du café",
        },
        coffee_variety: {
            en: "Coffee Variety",
            fr: "Variété de café",
        },
        coffee_ingredients: {
            en: "Coffee Ingredients",
            fr: "Ingrédients du café",
        },
        coffee_intensity: {
            en: "Coffee Intensity",
            fr: "Intensité du café",
        },
        coffee_label: {
            en: "Coffee Label",
            fr: "Label de café",
        },
        tea_type: {
            en: "Tea Type",
            fr: "Type de thé",
        },
        tea_color: {
            en: "Tea Color",
            fr: "Couleur de thé",
        },
        tea_flavor: {
            en: "Tea Flavor",
            fr: "Saveur de thé",
        },
        tea_label: {
            en: "Tea Label",
            fr: "Label de thé",
        },
        hot_beverage_type: {
            en: "Hot Beverage Type",
            fr: "Type de boisson chaude",
        },
        grocery_type: {
            en: "Grocery Type",
            fr: "Type d'épicerie",
        },
        material_type: {
            en: "Material Type",
            fr: "Type de matériau",
        },
        hygiene_type: {
            en: "Hygiene Type",
            fr: "Type d'hygiène",
        },
        container_format: {
            en: "Container Format",
            fr: "Format du contenant",
        },
        manufacturer_brand: {
            en: "Manufacturer Brand",
            fr: "Marque du fabricant",
        },
        deposit: {
            en: "Deposit",
            fr: "Consigne",
        },
        country: {
            en: "Country",
            fr: "Pays",
        },
    };

    const [expandedLevels, setExpandedLevels] = useState({
        beer: false,
        wine: false,
        wine_color: false,
        wine_vineyard: false,
        wine_denomination: false,
        wine_appellation: false,
        wine_millesime: false,
        wine_sugar_content: false,
        wine_grape: false,
        spirit_family: false,
        spirit_type: false,
        spirit_millesime: false,
        spirit_age: false,
        spirit_region: false,
        water_family: false,
        soft_family: false,
        juice_family: false,
        sirop_family: false,
        flavour: false,
        coffee_type: false,
        decaf_coffee: false,
        coffee_pod_type: false,
        coffee_roasting_region: false,
        coffee_roast: false,
        coffee_variety: false,
        coffee_ingredients: false,
        coffee_intensity: false,
        coffee_label: false,
        tea_type: false,
        tea_color: false,
        tea_flavor: false,
        tea_label: false,
        hot_beverage_type: false,
        grocery_type: false,
        material_type: false,
        hygiene_type: false,
        container_format: false,
        manufacturer_brand: false,
        deposit: false,
        country: false,
    });

    const toggleLevel = (level) => {
        setExpandedLevels((prev) => ({
            ...prev,
            [level]: !prev[level],
        }));
    };

    // Helper function to get the filter name in the selected language
    const getFilterName = (filterKey) => {
        return filterMappings[filterKey]
            ? filterMappings[filterKey][locale]
            : filterKey;
    };

    // State to control filter visibility
    const [isFilterOpen, setIsFilterOpen] = useState(false);

    const renderGeneralFilters = () => {
        const generalFilters = [
            "container_format",
            "manufacturer_brand",
            "deposit",
            "country",
        ];

        return generalFilters.map((filterKey) => (
            <div key={filterKey} className="mb-4">
                <button
                    className="font-semibold mb-2 flex items-center"
                    onClick={() => toggleLevel(filterKey)}
                >
                    {getFilterName(filterKey)}{" "}
                    {expandedLevels[filterKey] ? <FiMinus /> : <FiPlus />}
                </button>
                {expandedLevels[filterKey] && (
                    <div className="ml-4">
                        {extractUniqueValuesWithCount(filterKey)
                            .sort((a, b) => b[1] - a[1])
                            .map(([value, count]) => (
                                <div
                                    key={value}
                                    className="flex items-center mb-1"
                                >
                                    <input
                                        type="checkbox"
                                        id={`${filterKey}-${value}`}
                                        checked={selectedFilters[
                                            filterKey
                                        ]?.includes(value)}
                                        onChange={() =>
                                            handleFilterChange(filterKey, value)
                                        }
                                    />
                                    <label
                                        htmlFor={`${filterKey}-${value}`}
                                        className="ml-2 text-sm"
                                    >
                                        {value} ({count})
                                    </label>
                                </div>
                            ))}
                    </div>
                )}
            </div>
        ));
    };

    if (refreshing) {
        return <Loader />;
    }

    return (
        <IntlProvider locale={locale} messages={messages[locale]}>
            <div className="flex flex-row h-screen overflow-y-auto">
                <div className="w-full md:ml-20 xl:w-2/3 md:w-2/3 sm:w-2/3 px-15 text-left">
                    <div className="flex flex-col">
                        <div className="flex flex-col md:flex-row ml-5 md:mt-20">
                            <div>
                                <h1 className="md:mb-12 text-xl text-black mt-5 ">
                                    <FormattedMessage
                                        id="product.product_catalogue"
                                        values={{ number: 1 }}
                                    />
                                </h1>
                            </div>
                            <ProductSearch clients={products} />
                        </div>

                        <div className="mb-5 relative mt-5">
                            <div className="container px-5 text-left gray-50 items-top justify-center grid xl:grid-cols-3 grid-cols-2 gap-4">
                                {filteredProducts.length &&
                                    filteredProducts.map((prod) => (
                                        <ProductDetails
                                            product={prod}
                                            key={prod.product_id}
                                            locale={locale}
                                        />
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="md:hidden">
                    <button
                        className="fixed bottom-[100px] right-10 transform  bg-black text-white p-4 rounded-full shadow-lg z-50"
                        onClick={() => setIsFilterOpen(!isFilterOpen)}
                    >
                        {isFilterOpen ? <FiXCircle /> : <FiFilter />}
                    </button>
                </div>

                <div
                    className={`w-full bg-white md:w-1/5 sm:w-full pl-0 md:pl-20 ${
                        isFilterOpen ? "block" : "hidden"
                    } md:block fixed md:static top-0 left-0 z-10 pl-10 `}
                >
                    <div className="flex flex-col float-left mt-40 text-left max-h-[calc(100vh-200px)] overflow-y-auto pb-20 w-full">
                        <span className="font-bold mt-30">
                            {filteredProducts.length}{" "}
                            <FormattedMessage
                                id="product.products"
                                values={{ number: 1 }}
                            />
                        </span>
                        <div className="text-left mt-2">
                            <button
                                className="text-left flex items-center font-semibold mb-4" // Use flexbox for row display
                                onClick={() => toggleLevel("products")}
                            >
                                Categories produits
                                {expandedLevels["products"] ? (
                                    <FiMinus />
                                ) : (
                                    <FiPlus />
                                )}
                            </button>

                            {expandedLevels["products"] &&
                                productLevels.map((level1) => {
                                    // Calculate the number of products for this category (level1)
                                    const totalProductsInLevel =
                                        products.filter(
                                            (prod) =>
                                                prod.product_level_1 === level1
                                        ).length;
                                    return (
                                        <div
                                            key={level1}
                                            className="text-sm mb-3 text-left"
                                        >
                                            {/* <button
                                            className="text-sm text-left flex items-center" // Use flexbox for row display
                                            onClick={() => toggleLevel(level1)}
                                        >
                                            {level1}{" "}
                                            <span className="ml-1">
                                                ({totalProductsInLevel})
                                            </span>{" "}
                                            <FiChevronDown />
                                        </button> */}
                                            <div
                                                key={level1}
                                                className="text-sm mb-3 text-left flex flex-row"
                                            >
                                                <input
                                                    type="checkbox"
                                                    id={`product_level_1-${level1}`}
                                                    checked={selectedFilters.product_level_1.includes(
                                                        level1
                                                    )}
                                                    onChange={() =>
                                                        handleFilterChange(
                                                            "product_level_1",
                                                            level1
                                                        )
                                                    }
                                                />
                                                <label
                                                    htmlFor={`product_level_1-${level1}`}
                                                    className="ml-2"
                                                >
                                                    {level1} (
                                                    {totalProductsInLevel})
                                                </label>
                                                <button
                                                    className="text-sm text-left flex items-center ml-5" // Use flexbox for row display
                                                    onClick={() =>
                                                        toggleLevel(level1)
                                                    }
                                                >
                                                    {expandedLevels[level1] ? (
                                                        <FiMinus />
                                                    ) : (
                                                        <FiPlus />
                                                    )}
                                                </button>
                                            </div>
                                            {/* Display sublevels for each product level */}
                                            {expandedLevels[level1] && (
                                                <div className="ml-4">
                                                    {level1 === "BIERES" && (
                                                        <>
                                                            {/* Display Beer-related filters */}
                                                            {[
                                                                "beer_color",
                                                                "beer_family",
                                                                "beer_style",
                                                                "beer_flavor",
                                                                "beer_region",
                                                                "beer_head_type",
                                                                "beer_bitterness",
                                                                "gluten_free_beer",
                                                            ].map(
                                                                (sublevel) => (
                                                                    <div
                                                                        key={
                                                                            sublevel
                                                                        }
                                                                        className="mb-2"
                                                                    >
                                                                        <button
                                                                            onClick={() =>
                                                                                toggleLevel(
                                                                                    sublevel
                                                                                )
                                                                            }
                                                                            className="flex items-center"
                                                                        >
                                                                            <span>
                                                                                {getFilterName(
                                                                                    sublevel
                                                                                )}{" "}
                                                                            </span>
                                                                            <FiChevronDown />
                                                                        </button>
                                                                        {expandedLevels[
                                                                            sublevel
                                                                        ] && (
                                                                            <div className="ml-4">
                                                                                {extractUniqueValuesWithCount(
                                                                                    sublevel
                                                                                )
                                                                                    .sort(
                                                                                        (
                                                                                            a,
                                                                                            b
                                                                                        ) =>
                                                                                            b[1] -
                                                                                            a[1]
                                                                                    ) // Sort by count descending
                                                                                    .map(
                                                                                        ([
                                                                                            value,
                                                                                            count,
                                                                                        ]) => (
                                                                                            <div
                                                                                                key={
                                                                                                    value
                                                                                                }
                                                                                                className="flex items-center"
                                                                                            >
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id={`${sublevel}-${value}`}
                                                                                                    checked={selectedFilters[
                                                                                                        sublevel
                                                                                                    ]?.includes(
                                                                                                        value
                                                                                                    )}
                                                                                                    onChange={() =>
                                                                                                        handleFilterChange(
                                                                                                            sublevel,
                                                                                                            value
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                                <label
                                                                                                    htmlFor={`${sublevel}-${value}`}
                                                                                                    className="ml-2 text-sm"
                                                                                                >
                                                                                                    {
                                                                                                        value
                                                                                                    }{" "}
                                                                                                    (
                                                                                                    {
                                                                                                        count
                                                                                                    }

                                                                                                    )
                                                                                                </label>
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )
                                                            )}
                                                        </>
                                                    )}
                                                    {level1 === "VINS" && (
                                                        <>
                                                            {/* Display Wine-related filters */}
                                                            {[
                                                                "wine_color",
                                                                "wine_vineyard",
                                                                "wine_denomination",
                                                                "wine_appellation",
                                                                "wine_millesime",
                                                                "wine_sugar_content",
                                                                "wine_grape",
                                                            ].map(
                                                                (sublevel) => (
                                                                    <div
                                                                        key={
                                                                            sublevel
                                                                        }
                                                                        className="mb-2"
                                                                    >
                                                                        <button
                                                                            onClick={() =>
                                                                                toggleLevel(
                                                                                    sublevel
                                                                                )
                                                                            }
                                                                            className="flex items-center"
                                                                        >
                                                                            <span>
                                                                                {getFilterName(
                                                                                    sublevel
                                                                                )}
                                                                            </span>
                                                                            <FiChevronDown />
                                                                        </button>
                                                                        {expandedLevels[
                                                                            sublevel
                                                                        ] && (
                                                                            <div className="ml-4">
                                                                                {extractUniqueValuesWithCount(
                                                                                    sublevel
                                                                                )
                                                                                    .sort(
                                                                                        (
                                                                                            a,
                                                                                            b
                                                                                        ) =>
                                                                                            b[1] -
                                                                                            a[1]
                                                                                    ) // Sort by count descending
                                                                                    .map(
                                                                                        ([
                                                                                            value,
                                                                                            count,
                                                                                        ]) => (
                                                                                            <div
                                                                                                key={
                                                                                                    value
                                                                                                }
                                                                                                className="flex items-center"
                                                                                            >
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id={`${sublevel}-${value}`}
                                                                                                    checked={selectedFilters[
                                                                                                        sublevel
                                                                                                    ]?.includes(
                                                                                                        value
                                                                                                    )}
                                                                                                    onChange={() =>
                                                                                                        handleFilterChange(
                                                                                                            sublevel,
                                                                                                            value
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                                <label
                                                                                                    htmlFor={`${sublevel}-${value}`}
                                                                                                    className="ml-2 text-sm"
                                                                                                >
                                                                                                    {
                                                                                                        value
                                                                                                    }{" "}
                                                                                                    (
                                                                                                    {
                                                                                                        count
                                                                                                    }

                                                                                                    )
                                                                                                </label>
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )
                                                            )}
                                                        </>
                                                    )}
                                                    {level1 === "CHOCOLATS" && (
                                                        <>
                                                            {/* Display Wine-related filters */}
                                                            {[
                                                                "hot_beverage_type",
                                                            ].map(
                                                                (sublevel) => (
                                                                    <div
                                                                        key={
                                                                            sublevel
                                                                        }
                                                                        className="mb-2"
                                                                    >
                                                                        <button
                                                                            onClick={() =>
                                                                                toggleLevel(
                                                                                    sublevel
                                                                                )
                                                                            }
                                                                            className="flex items-center"
                                                                        >
                                                                            <span>
                                                                                {getFilterName(
                                                                                    sublevel
                                                                                )}
                                                                            </span>
                                                                            <FiChevronDown />
                                                                        </button>
                                                                        {expandedLevels[
                                                                            sublevel
                                                                        ] && (
                                                                            <div className="ml-4">
                                                                                {extractUniqueValuesWithCount(
                                                                                    sublevel
                                                                                )
                                                                                    .sort(
                                                                                        (
                                                                                            a,
                                                                                            b
                                                                                        ) =>
                                                                                            b[1] -
                                                                                            a[1]
                                                                                    ) // Sort by count descending
                                                                                    .map(
                                                                                        ([
                                                                                            value,
                                                                                            count,
                                                                                        ]) => (
                                                                                            <div
                                                                                                key={
                                                                                                    value
                                                                                                }
                                                                                                className="flex items-center"
                                                                                            >
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id={`${sublevel}-${value}`}
                                                                                                    checked={selectedFilters[
                                                                                                        sublevel
                                                                                                    ]?.includes(
                                                                                                        value
                                                                                                    )}
                                                                                                    onChange={() =>
                                                                                                        handleFilterChange(
                                                                                                            sublevel,
                                                                                                            value
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                                <label
                                                                                                    htmlFor={`${sublevel}-${value}`}
                                                                                                    className="ml-2 text-sm"
                                                                                                >
                                                                                                    {
                                                                                                        value
                                                                                                    }{" "}
                                                                                                    (
                                                                                                    {
                                                                                                        count
                                                                                                    }

                                                                                                    )
                                                                                                </label>
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )
                                                            )}
                                                        </>
                                                    )}
                                                    {level1 === "CIDRES" && (
                                                        <>
                                                            {/* Display Wine-related filters */}
                                                            {[
                                                                "cider_type",
                                                                "cider_head_type",
                                                                "cider_flavor",
                                                                "cider_region",
                                                            ].map(
                                                                (sublevel) => (
                                                                    <div
                                                                        key={
                                                                            sublevel
                                                                        }
                                                                        className="mb-2"
                                                                    >
                                                                        <button
                                                                            onClick={() =>
                                                                                toggleLevel(
                                                                                    sublevel
                                                                                )
                                                                            }
                                                                            className="flex items-center"
                                                                        >
                                                                            <span>
                                                                                {getFilterName(
                                                                                    sublevel
                                                                                )}
                                                                            </span>
                                                                            <FiChevronDown />
                                                                        </button>
                                                                        {expandedLevels[
                                                                            sublevel
                                                                        ] && (
                                                                            <div className="ml-4">
                                                                                {extractUniqueValuesWithCount(
                                                                                    sublevel
                                                                                )
                                                                                    .sort(
                                                                                        (
                                                                                            a,
                                                                                            b
                                                                                        ) =>
                                                                                            b[1] -
                                                                                            a[1]
                                                                                    ) // Sort by count descending
                                                                                    .map(
                                                                                        ([
                                                                                            value,
                                                                                            count,
                                                                                        ]) => (
                                                                                            <div
                                                                                                key={
                                                                                                    value
                                                                                                }
                                                                                                className="flex items-center"
                                                                                            >
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id={`${sublevel}-${value}`}
                                                                                                    checked={selectedFilters[
                                                                                                        sublevel
                                                                                                    ]?.includes(
                                                                                                        value
                                                                                                    )}
                                                                                                    onChange={() =>
                                                                                                        handleFilterChange(
                                                                                                            sublevel,
                                                                                                            value
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                                <label
                                                                                                    htmlFor={`${sublevel}-${value}`}
                                                                                                    className="ml-2 text-sm"
                                                                                                >
                                                                                                    {
                                                                                                        value
                                                                                                    }{" "}
                                                                                                    (
                                                                                                    {
                                                                                                        count
                                                                                                    }

                                                                                                    )
                                                                                                </label>
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )
                                                            )}
                                                        </>
                                                    )}
                                                    {level1 === "CIDERS" && (
                                                        <>
                                                            {/* Display Wine-related filters */}
                                                            {[
                                                                "cider_type",
                                                                "cider_head_type",
                                                                "cider_flavor",
                                                                "cider_region",
                                                            ].map(
                                                                (sublevel) => (
                                                                    <div
                                                                        key={
                                                                            sublevel
                                                                        }
                                                                        className="mb-2"
                                                                    >
                                                                        <button
                                                                            onClick={() =>
                                                                                toggleLevel(
                                                                                    sublevel
                                                                                )
                                                                            }
                                                                            className="flex items-center"
                                                                        >
                                                                            <span>
                                                                                {getFilterName(
                                                                                    sublevel
                                                                                )}
                                                                            </span>
                                                                            <FiChevronDown />
                                                                        </button>
                                                                        {expandedLevels[
                                                                            sublevel
                                                                        ] && (
                                                                            <div className="ml-4">
                                                                                {extractUniqueValuesWithCount(
                                                                                    sublevel
                                                                                )
                                                                                    .sort(
                                                                                        (
                                                                                            a,
                                                                                            b
                                                                                        ) =>
                                                                                            b[1] -
                                                                                            a[1]
                                                                                    ) // Sort by count descending
                                                                                    .map(
                                                                                        ([
                                                                                            value,
                                                                                            count,
                                                                                        ]) => (
                                                                                            <div
                                                                                                key={
                                                                                                    value
                                                                                                }
                                                                                                className="flex items-center"
                                                                                            >
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id={`${sublevel}-${value}`}
                                                                                                    checked={selectedFilters[
                                                                                                        sublevel
                                                                                                    ]?.includes(
                                                                                                        value
                                                                                                    )}
                                                                                                    onChange={() =>
                                                                                                        handleFilterChange(
                                                                                                            sublevel,
                                                                                                            value
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                                <label
                                                                                                    htmlFor={`${sublevel}-${value}`}
                                                                                                    className="ml-2 text-sm"
                                                                                                >
                                                                                                    {
                                                                                                        value
                                                                                                    }{" "}
                                                                                                    (
                                                                                                    {
                                                                                                        count
                                                                                                    }

                                                                                                    )
                                                                                                </label>
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )
                                                            )}
                                                        </>
                                                    )}
                                                    {level1 ===
                                                        "SOFT DRINKS" && (
                                                        <>
                                                            {/* Display Beer-related filters */}
                                                            {[
                                                                "soft_family",
                                                            ].map(
                                                                (sublevel) => (
                                                                    <div
                                                                        key={
                                                                            sublevel
                                                                        }
                                                                        className="mb-2"
                                                                    >
                                                                        <button
                                                                            onClick={() =>
                                                                                toggleLevel(
                                                                                    sublevel
                                                                                )
                                                                            }
                                                                            className="flex items-center"
                                                                        >
                                                                            <span>
                                                                                {getFilterName(
                                                                                    sublevel
                                                                                )}{" "}
                                                                            </span>
                                                                            <FiChevronDown />
                                                                        </button>
                                                                        {expandedLevels[
                                                                            sublevel
                                                                        ] && (
                                                                            <div className="ml-4">
                                                                                {extractUniqueValuesWithCount(
                                                                                    sublevel
                                                                                )
                                                                                    .sort(
                                                                                        (
                                                                                            a,
                                                                                            b
                                                                                        ) =>
                                                                                            b[1] -
                                                                                            a[1]
                                                                                    ) // Sort by count descending
                                                                                    .map(
                                                                                        ([
                                                                                            value,
                                                                                            count,
                                                                                        ]) => (
                                                                                            <div
                                                                                                key={
                                                                                                    value
                                                                                                }
                                                                                                className="flex items-center"
                                                                                            >
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id={`${sublevel}-${value}`}
                                                                                                    checked={selectedFilters[
                                                                                                        sublevel
                                                                                                    ]?.includes(
                                                                                                        value
                                                                                                    )}
                                                                                                    onChange={() =>
                                                                                                        handleFilterChange(
                                                                                                            sublevel,
                                                                                                            value
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                                <label
                                                                                                    htmlFor={`${sublevel}-${value}`}
                                                                                                    className="ml-2 text-sm"
                                                                                                >
                                                                                                    {
                                                                                                        value
                                                                                                    }{" "}
                                                                                                    (
                                                                                                    {
                                                                                                        count
                                                                                                    }

                                                                                                    )
                                                                                                </label>
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )
                                                            )}
                                                        </>
                                                    )}

                                                    {level1 ===
                                                        "SIROP ET CONCENTRES" && (
                                                        <>
                                                            {/* Display Wine-related filters */}
                                                            {[
                                                                "sirop_family",
                                                                "flavour",
                                                            ].map(
                                                                (sublevel) => (
                                                                    <div
                                                                        key={
                                                                            sublevel
                                                                        }
                                                                        className="mb-2"
                                                                    >
                                                                        <button
                                                                            onClick={() =>
                                                                                toggleLevel(
                                                                                    sublevel
                                                                                )
                                                                            }
                                                                            className="flex items-center"
                                                                        >
                                                                            <span>
                                                                                {getFilterName(
                                                                                    sublevel
                                                                                )}
                                                                            </span>
                                                                            <FiChevronDown />
                                                                        </button>
                                                                        {expandedLevels[
                                                                            sublevel
                                                                        ] && (
                                                                            <div className="ml-4">
                                                                                {extractUniqueValuesWithCount(
                                                                                    sublevel
                                                                                )
                                                                                    .sort(
                                                                                        (
                                                                                            a,
                                                                                            b
                                                                                        ) =>
                                                                                            b[1] -
                                                                                            a[1]
                                                                                    ) // Sort by count descending
                                                                                    .map(
                                                                                        ([
                                                                                            value,
                                                                                            count,
                                                                                        ]) => (
                                                                                            <div
                                                                                                key={
                                                                                                    value
                                                                                                }
                                                                                                className="flex items-center"
                                                                                            >
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id={`${sublevel}-${value}`}
                                                                                                    checked={selectedFilters[
                                                                                                        sublevel
                                                                                                    ]?.includes(
                                                                                                        value
                                                                                                    )}
                                                                                                    onChange={() =>
                                                                                                        handleFilterChange(
                                                                                                            sublevel,
                                                                                                            value
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                                <label
                                                                                                    htmlFor={`${sublevel}-${value}`}
                                                                                                    className="ml-2 text-sm"
                                                                                                >
                                                                                                    {
                                                                                                        value
                                                                                                    }{" "}
                                                                                                    (
                                                                                                    {
                                                                                                        count
                                                                                                    }

                                                                                                    )
                                                                                                </label>
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )
                                                            )}
                                                        </>
                                                    )}
                                                    {level1 ===
                                                        "LAITS ET SAUCES" && (
                                                        <>
                                                            {/* Display Wine-related filters */}
                                                            {[
                                                                "grocery_type",
                                                            ].map(
                                                                (sublevel) => (
                                                                    <div
                                                                        key={
                                                                            sublevel
                                                                        }
                                                                        className="mb-2"
                                                                    >
                                                                        <button
                                                                            onClick={() =>
                                                                                toggleLevel(
                                                                                    sublevel
                                                                                )
                                                                            }
                                                                            className="flex items-center"
                                                                        >
                                                                            <span>
                                                                                {getFilterName(
                                                                                    sublevel
                                                                                )}
                                                                            </span>
                                                                            <FiChevronDown />
                                                                        </button>
                                                                        {expandedLevels[
                                                                            sublevel
                                                                        ] && (
                                                                            <div className="ml-4">
                                                                                {extractUniqueValuesWithCount(
                                                                                    sublevel
                                                                                )
                                                                                    .sort(
                                                                                        (
                                                                                            a,
                                                                                            b
                                                                                        ) =>
                                                                                            b[1] -
                                                                                            a[1]
                                                                                    ) // Sort by count descending
                                                                                    .map(
                                                                                        ([
                                                                                            value,
                                                                                            count,
                                                                                        ]) => (
                                                                                            <div
                                                                                                key={
                                                                                                    value
                                                                                                }
                                                                                                className="flex items-center"
                                                                                            >
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id={`${sublevel}-${value}`}
                                                                                                    checked={selectedFilters[
                                                                                                        sublevel
                                                                                                    ]?.includes(
                                                                                                        value
                                                                                                    )}
                                                                                                    onChange={() =>
                                                                                                        handleFilterChange(
                                                                                                            sublevel,
                                                                                                            value
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                                <label
                                                                                                    htmlFor={`${sublevel}-${value}`}
                                                                                                    className="ml-2 text-sm"
                                                                                                >
                                                                                                    {
                                                                                                        value
                                                                                                    }{" "}
                                                                                                    (
                                                                                                    {
                                                                                                        count
                                                                                                    }

                                                                                                    )
                                                                                                </label>
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )
                                                            )}
                                                        </>
                                                    )}
                                                    {level1 ===
                                                        "SUCRES ET ACCOMPAGNEMENTS" && (
                                                        <>
                                                            {/* Display Wine-related filters */}
                                                            {[
                                                                "grocery_type",
                                                            ].map(
                                                                (sublevel) => (
                                                                    <div
                                                                        key={
                                                                            sublevel
                                                                        }
                                                                        className="mb-2"
                                                                    >
                                                                        <button
                                                                            onClick={() =>
                                                                                toggleLevel(
                                                                                    sublevel
                                                                                )
                                                                            }
                                                                            className="flex items-center"
                                                                        >
                                                                            <span>
                                                                                {getFilterName(
                                                                                    sublevel
                                                                                )}
                                                                            </span>
                                                                            <FiChevronDown />
                                                                        </button>
                                                                        {expandedLevels[
                                                                            sublevel
                                                                        ] && (
                                                                            <div className="ml-4">
                                                                                {extractUniqueValuesWithCount(
                                                                                    sublevel
                                                                                )
                                                                                    .sort(
                                                                                        (
                                                                                            a,
                                                                                            b
                                                                                        ) =>
                                                                                            b[1] -
                                                                                            a[1]
                                                                                    ) // Sort by count descending
                                                                                    .map(
                                                                                        ([
                                                                                            value,
                                                                                            count,
                                                                                        ]) => (
                                                                                            <div
                                                                                                key={
                                                                                                    value
                                                                                                }
                                                                                                className="flex items-center"
                                                                                            >
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id={`${sublevel}-${value}`}
                                                                                                    checked={selectedFilters[
                                                                                                        sublevel
                                                                                                    ]?.includes(
                                                                                                        value
                                                                                                    )}
                                                                                                    onChange={() =>
                                                                                                        handleFilterChange(
                                                                                                            sublevel,
                                                                                                            value
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                                <label
                                                                                                    htmlFor={`${sublevel}-${value}`}
                                                                                                    className="ml-2 text-sm"
                                                                                                >
                                                                                                    {
                                                                                                        value
                                                                                                    }{" "}
                                                                                                    (
                                                                                                    {
                                                                                                        count
                                                                                                    }

                                                                                                    )
                                                                                                </label>
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )
                                                            )}
                                                        </>
                                                    )}
                                                    {level1 ===
                                                        "SPIRITUEUX" && (
                                                        <>
                                                            {/* Display Wine-related filters */}
                                                            {[
                                                                "spirit_family",
                                                                "spirit_type",
                                                                "spirit_millesime",
                                                                "spirit_age",
                                                                "spirit_region",
                                                            ].map(
                                                                (sublevel) => (
                                                                    <div
                                                                        key={
                                                                            sublevel
                                                                        }
                                                                        className="mb-2"
                                                                    >
                                                                        <button
                                                                            onClick={() =>
                                                                                toggleLevel(
                                                                                    sublevel
                                                                                )
                                                                            }
                                                                            className="flex items-center"
                                                                        >
                                                                            <span>
                                                                                {getFilterName(
                                                                                    sublevel
                                                                                )}
                                                                            </span>
                                                                            <FiChevronDown />
                                                                        </button>
                                                                        {expandedLevels[
                                                                            sublevel
                                                                        ] && (
                                                                            <div className="ml-4">
                                                                                {extractUniqueValuesWithCount(
                                                                                    sublevel
                                                                                )
                                                                                    .sort(
                                                                                        (
                                                                                            a,
                                                                                            b
                                                                                        ) =>
                                                                                            b[1] -
                                                                                            a[1]
                                                                                    ) // Sort by count descending
                                                                                    .map(
                                                                                        ([
                                                                                            value,
                                                                                            count,
                                                                                        ]) => (
                                                                                            <div
                                                                                                key={
                                                                                                    value
                                                                                                }
                                                                                                className="flex items-center"
                                                                                            >
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id={`${sublevel}-${value}`}
                                                                                                    checked={selectedFilters[
                                                                                                        sublevel
                                                                                                    ]?.includes(
                                                                                                        value
                                                                                                    )}
                                                                                                    onChange={() =>
                                                                                                        handleFilterChange(
                                                                                                            sublevel,
                                                                                                            value
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                                <label
                                                                                                    htmlFor={`${sublevel}-${value}`}
                                                                                                    className="ml-2 text-sm"
                                                                                                >
                                                                                                    {
                                                                                                        value
                                                                                                    }{" "}
                                                                                                    (
                                                                                                    {
                                                                                                        count
                                                                                                    }

                                                                                                    )
                                                                                                </label>
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )
                                                            )}
                                                        </>
                                                    )}
                                                    {level1 === "HYGIENES" && (
                                                        <>
                                                            {/* Display Beer-related filters */}
                                                            {[
                                                                "hygiene_type",
                                                            ].map(
                                                                (sublevel) => (
                                                                    <div
                                                                        key={
                                                                            sublevel
                                                                        }
                                                                        className="mb-2"
                                                                    >
                                                                        <button
                                                                            onClick={() =>
                                                                                toggleLevel(
                                                                                    sublevel
                                                                                )
                                                                            }
                                                                            className="flex items-center"
                                                                        >
                                                                            <span>
                                                                                {getFilterName(
                                                                                    sublevel
                                                                                )}{" "}
                                                                            </span>
                                                                            <FiChevronDown />
                                                                        </button>
                                                                        {expandedLevels[
                                                                            sublevel
                                                                        ] && (
                                                                            <div className="ml-4">
                                                                                {extractUniqueValuesWithCount(
                                                                                    sublevel
                                                                                )
                                                                                    .sort(
                                                                                        (
                                                                                            a,
                                                                                            b
                                                                                        ) =>
                                                                                            b[1] -
                                                                                            a[1]
                                                                                    ) // Sort by count descending
                                                                                    .map(
                                                                                        ([
                                                                                            value,
                                                                                            count,
                                                                                        ]) => (
                                                                                            <div
                                                                                                key={
                                                                                                    value
                                                                                                }
                                                                                                className="flex items-center"
                                                                                            >
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id={`${sublevel}-${value}`}
                                                                                                    checked={selectedFilters[
                                                                                                        sublevel
                                                                                                    ]?.includes(
                                                                                                        value
                                                                                                    )}
                                                                                                    onChange={() =>
                                                                                                        handleFilterChange(
                                                                                                            sublevel,
                                                                                                            value
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                                <label
                                                                                                    htmlFor={`${sublevel}-${value}`}
                                                                                                    className="ml-2 text-sm"
                                                                                                >
                                                                                                    {
                                                                                                        value
                                                                                                    }{" "}
                                                                                                    (
                                                                                                    {
                                                                                                        count
                                                                                                    }

                                                                                                    )
                                                                                                </label>
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )
                                                            )}
                                                        </>
                                                    )}

                                                    {level1 === "SNACKING" && (
                                                        <>
                                                            {/* Display Wine-related filters */}
                                                            {[
                                                                "wine_color",
                                                                "wine_vineyard",
                                                                "wine_denomination",
                                                                "wine_appellation",
                                                                "wine_millesime",
                                                                "wine_sugar_content",
                                                                "wine_grape",
                                                            ].map(
                                                                (sublevel) => (
                                                                    <div
                                                                        key={
                                                                            sublevel
                                                                        }
                                                                        className="mb-2"
                                                                    >
                                                                        <button
                                                                            onClick={() =>
                                                                                toggleLevel(
                                                                                    sublevel
                                                                                )
                                                                            }
                                                                            className="flex items-center"
                                                                        >
                                                                            <span>
                                                                                {getFilterName(
                                                                                    sublevel
                                                                                )}
                                                                            </span>
                                                                            <FiChevronDown />
                                                                        </button>
                                                                        {expandedLevels[
                                                                            sublevel
                                                                        ] && (
                                                                            <div className="ml-4">
                                                                                {extractUniqueValuesWithCount(
                                                                                    sublevel
                                                                                )
                                                                                    .sort(
                                                                                        (
                                                                                            a,
                                                                                            b
                                                                                        ) =>
                                                                                            b[1] -
                                                                                            a[1]
                                                                                    ) // Sort by count descending
                                                                                    .map(
                                                                                        ([
                                                                                            value,
                                                                                            count,
                                                                                        ]) => (
                                                                                            <div
                                                                                                key={
                                                                                                    value
                                                                                                }
                                                                                                className="flex items-center"
                                                                                            >
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id={`${sublevel}-${value}`}
                                                                                                    checked={selectedFilters[
                                                                                                        sublevel
                                                                                                    ]?.includes(
                                                                                                        value
                                                                                                    )}
                                                                                                    onChange={() =>
                                                                                                        handleFilterChange(
                                                                                                            sublevel,
                                                                                                            value
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                                <label
                                                                                                    htmlFor={`${sublevel}-${value}`}
                                                                                                    className="ml-2 text-sm"
                                                                                                >
                                                                                                    {
                                                                                                        value
                                                                                                    }{" "}
                                                                                                    (
                                                                                                    {
                                                                                                        count
                                                                                                    }

                                                                                                    )
                                                                                                </label>
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )
                                                            )}
                                                        </>
                                                    )}
                                                    {level1 ===
                                                        "JUS DE FRUITS" && (
                                                        <>
                                                            {/* Display Wine-related filters */}
                                                            {[
                                                                "juice_family",
                                                            ].map(
                                                                (sublevel) => (
                                                                    <div
                                                                        key={
                                                                            sublevel
                                                                        }
                                                                        className="mb-2"
                                                                    >
                                                                        <button
                                                                            onClick={() =>
                                                                                toggleLevel(
                                                                                    sublevel
                                                                                )
                                                                            }
                                                                            className="flex items-center"
                                                                        >
                                                                            <span>
                                                                                {getFilterName(
                                                                                    sublevel
                                                                                )}
                                                                            </span>
                                                                            <FiChevronDown />
                                                                        </button>
                                                                        {expandedLevels[
                                                                            sublevel
                                                                        ] && (
                                                                            <div className="ml-4">
                                                                                {extractUniqueValuesWithCount(
                                                                                    sublevel
                                                                                )
                                                                                    .sort(
                                                                                        (
                                                                                            a,
                                                                                            b
                                                                                        ) =>
                                                                                            b[1] -
                                                                                            a[1]
                                                                                    ) // Sort by count descending
                                                                                    .map(
                                                                                        ([
                                                                                            value,
                                                                                            count,
                                                                                        ]) => (
                                                                                            <div
                                                                                                key={
                                                                                                    value
                                                                                                }
                                                                                                className="flex items-center"
                                                                                            >
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id={`${sublevel}-${value}`}
                                                                                                    checked={selectedFilters[
                                                                                                        sublevel
                                                                                                    ]?.includes(
                                                                                                        value
                                                                                                    )}
                                                                                                    onChange={() =>
                                                                                                        handleFilterChange(
                                                                                                            sublevel,
                                                                                                            value
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                                <label
                                                                                                    htmlFor={`${sublevel}-${value}`}
                                                                                                    className="ml-2 text-sm"
                                                                                                >
                                                                                                    {
                                                                                                        value
                                                                                                    }{" "}
                                                                                                    (
                                                                                                    {
                                                                                                        count
                                                                                                    }

                                                                                                    )
                                                                                                </label>
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )
                                                            )}
                                                        </>
                                                    )}
                                                    {level1 ===
                                                        "ASSAISONNEMENTS" && (
                                                        <>
                                                            {/* Display Wine-related filters */}
                                                            {[
                                                                "wine_color",
                                                                "wine_vineyard",
                                                                "wine_denomination",
                                                                "wine_appellation",
                                                                "wine_millesime",
                                                                "wine_sugar_content",
                                                                "wine_grape",
                                                            ].map(
                                                                (sublevel) => (
                                                                    <div
                                                                        key={
                                                                            sublevel
                                                                        }
                                                                        className="mb-2"
                                                                    >
                                                                        <button
                                                                            onClick={() =>
                                                                                toggleLevel(
                                                                                    sublevel
                                                                                )
                                                                            }
                                                                            className="flex items-center"
                                                                        >
                                                                            <span>
                                                                                {getFilterName(
                                                                                    sublevel
                                                                                )}
                                                                            </span>
                                                                            <FiChevronDown />
                                                                        </button>
                                                                        {expandedLevels[
                                                                            sublevel
                                                                        ] && (
                                                                            <div className="ml-4">
                                                                                {extractUniqueValuesWithCount(
                                                                                    sublevel
                                                                                )
                                                                                    .sort(
                                                                                        (
                                                                                            a,
                                                                                            b
                                                                                        ) =>
                                                                                            b[1] -
                                                                                            a[1]
                                                                                    ) // Sort by count descending
                                                                                    .map(
                                                                                        ([
                                                                                            value,
                                                                                            count,
                                                                                        ]) => (
                                                                                            <div
                                                                                                key={
                                                                                                    value
                                                                                                }
                                                                                                className="flex items-center"
                                                                                            >
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id={`${sublevel}-${value}`}
                                                                                                    checked={selectedFilters[
                                                                                                        sublevel
                                                                                                    ]?.includes(
                                                                                                        value
                                                                                                    )}
                                                                                                    onChange={() =>
                                                                                                        handleFilterChange(
                                                                                                            sublevel,
                                                                                                            value
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                                <label
                                                                                                    htmlFor={`${sublevel}-${value}`}
                                                                                                    className="ml-2 text-sm"
                                                                                                >
                                                                                                    {
                                                                                                        value
                                                                                                    }{" "}
                                                                                                    (
                                                                                                    {
                                                                                                        count
                                                                                                    }

                                                                                                    )
                                                                                                </label>
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )
                                                            )}
                                                        </>
                                                    )}
                                                    {level1 === "MATERIELS" && (
                                                        <>
                                                            {/* Display Wine-related filters */}
                                                            {[
                                                                "material_type",
                                                            ].map(
                                                                (sublevel) => (
                                                                    <div
                                                                        key={
                                                                            sublevel
                                                                        }
                                                                        className="mb-2"
                                                                    >
                                                                        <button
                                                                            onClick={() =>
                                                                                toggleLevel(
                                                                                    sublevel
                                                                                )
                                                                            }
                                                                            className="flex items-center"
                                                                        >
                                                                            <span>
                                                                                {getFilterName(
                                                                                    sublevel
                                                                                )}
                                                                            </span>
                                                                            <FiChevronDown />
                                                                        </button>
                                                                        {expandedLevels[
                                                                            sublevel
                                                                        ] && (
                                                                            <div className="ml-4">
                                                                                {extractUniqueValuesWithCount(
                                                                                    sublevel
                                                                                )
                                                                                    .sort(
                                                                                        (
                                                                                            a,
                                                                                            b
                                                                                        ) =>
                                                                                            b[1] -
                                                                                            a[1]
                                                                                    ) // Sort by count descending
                                                                                    .map(
                                                                                        ([
                                                                                            value,
                                                                                            count,
                                                                                        ]) => (
                                                                                            <div
                                                                                                key={
                                                                                                    value
                                                                                                }
                                                                                                className="flex items-center"
                                                                                            >
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id={`${sublevel}-${value}`}
                                                                                                    checked={selectedFilters[
                                                                                                        sublevel
                                                                                                    ]?.includes(
                                                                                                        value
                                                                                                    )}
                                                                                                    onChange={() =>
                                                                                                        handleFilterChange(
                                                                                                            sublevel,
                                                                                                            value
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                                <label
                                                                                                    htmlFor={`${sublevel}-${value}`}
                                                                                                    className="ml-2 text-sm"
                                                                                                >
                                                                                                    {
                                                                                                        value
                                                                                                    }{" "}
                                                                                                    (
                                                                                                    {
                                                                                                        count
                                                                                                    }

                                                                                                    )
                                                                                                </label>
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )
                                                            )}
                                                        </>
                                                    )}
                                                    {level1 ===
                                                        "THES ET INFUSIONS" && (
                                                        <>
                                                            {/* Display Beer-related filters */}
                                                            {[
                                                                "tea_type",
                                                                "tea_color",
                                                                "tea_flavor",
                                                                "tea_label",
                                                            ].map(
                                                                (sublevel) => (
                                                                    <div
                                                                        key={
                                                                            sublevel
                                                                        }
                                                                        className="mb-2"
                                                                    >
                                                                        <button
                                                                            onClick={() =>
                                                                                toggleLevel(
                                                                                    sublevel
                                                                                )
                                                                            }
                                                                            className="flex items-center"
                                                                        >
                                                                            <span>
                                                                                {getFilterName(
                                                                                    sublevel
                                                                                )}{" "}
                                                                            </span>
                                                                            <FiChevronDown />
                                                                        </button>
                                                                        {expandedLevels[
                                                                            sublevel
                                                                        ] && (
                                                                            <div className="ml-4">
                                                                                {extractUniqueValuesWithCount(
                                                                                    sublevel
                                                                                )
                                                                                    .sort(
                                                                                        (
                                                                                            a,
                                                                                            b
                                                                                        ) =>
                                                                                            b[1] -
                                                                                            a[1]
                                                                                    ) // Sort by count descending
                                                                                    .map(
                                                                                        ([
                                                                                            value,
                                                                                            count,
                                                                                        ]) => (
                                                                                            <div
                                                                                                key={
                                                                                                    value
                                                                                                }
                                                                                                className="flex items-center"
                                                                                            >
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id={`${sublevel}-${value}`}
                                                                                                    checked={selectedFilters[
                                                                                                        sublevel
                                                                                                    ]?.includes(
                                                                                                        value
                                                                                                    )}
                                                                                                    onChange={() =>
                                                                                                        handleFilterChange(
                                                                                                            sublevel,
                                                                                                            value
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                                <label
                                                                                                    htmlFor={`${sublevel}-${value}`}
                                                                                                    className="ml-2 text-sm"
                                                                                                >
                                                                                                    {
                                                                                                        value
                                                                                                    }{" "}
                                                                                                    (
                                                                                                    {
                                                                                                        count
                                                                                                    }

                                                                                                    )
                                                                                                </label>
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )
                                                            )}
                                                        </>
                                                    )}

                                                    {level1 === "EAUX" && (
                                                        <>
                                                            {/* Display Wine-related filters */}
                                                            {[
                                                                "water_family",
                                                            ].map(
                                                                (sublevel) => (
                                                                    <div
                                                                        key={
                                                                            sublevel
                                                                        }
                                                                        className="mb-2"
                                                                    >
                                                                        <button
                                                                            onClick={() =>
                                                                                toggleLevel(
                                                                                    sublevel
                                                                                )
                                                                            }
                                                                            className="flex items-center"
                                                                        >
                                                                            <span>
                                                                                {getFilterName(
                                                                                    sublevel
                                                                                )}
                                                                            </span>
                                                                            <FiChevronDown />
                                                                        </button>
                                                                        {expandedLevels[
                                                                            sublevel
                                                                        ] && (
                                                                            <div className="ml-4">
                                                                                {extractUniqueValuesWithCount(
                                                                                    sublevel
                                                                                )
                                                                                    .sort(
                                                                                        (
                                                                                            a,
                                                                                            b
                                                                                        ) =>
                                                                                            b[1] -
                                                                                            a[1]
                                                                                    ) // Sort by count descending
                                                                                    .map(
                                                                                        ([
                                                                                            value,
                                                                                            count,
                                                                                        ]) => (
                                                                                            <div
                                                                                                key={
                                                                                                    value
                                                                                                }
                                                                                                className="flex items-center"
                                                                                            >
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id={`${sublevel}-${value}`}
                                                                                                    checked={selectedFilters[
                                                                                                        sublevel
                                                                                                    ]?.includes(
                                                                                                        value
                                                                                                    )}
                                                                                                    onChange={() =>
                                                                                                        handleFilterChange(
                                                                                                            sublevel,
                                                                                                            value
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                                <label
                                                                                                    htmlFor={`${sublevel}-${value}`}
                                                                                                    className="ml-2 text-sm"
                                                                                                >
                                                                                                    {
                                                                                                        value
                                                                                                    }{" "}
                                                                                                    (
                                                                                                    {
                                                                                                        count
                                                                                                    }

                                                                                                    )
                                                                                                </label>
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )
                                                            )}
                                                        </>
                                                    )}
                                                    {level1 === "CAFES" && (
                                                        <>
                                                            {/* Display Wine-related filters */}
                                                            {[
                                                                "coffee_type",
                                                                "decaf_coffee",
                                                                "coffee_pod_type",
                                                                "coffee_roasting_region",
                                                                "coffee_roast",
                                                                "coffee_variety",
                                                                "coffee_ingredients",
                                                                "coffee_intensity",
                                                                "coffee_label",
                                                            ].map(
                                                                (sublevel) => (
                                                                    <div
                                                                        key={
                                                                            sublevel
                                                                        }
                                                                        className="mb-2"
                                                                    >
                                                                        <button
                                                                            onClick={() =>
                                                                                toggleLevel(
                                                                                    sublevel
                                                                                )
                                                                            }
                                                                            className="flex items-center"
                                                                        >
                                                                            <span>
                                                                                {getFilterName(
                                                                                    sublevel
                                                                                )}
                                                                            </span>
                                                                            <FiChevronDown />
                                                                        </button>
                                                                        {expandedLevels[
                                                                            sublevel
                                                                        ] && (
                                                                            <div className="ml-4">
                                                                                {extractUniqueValuesWithCount(
                                                                                    sublevel
                                                                                )
                                                                                    .sort(
                                                                                        (
                                                                                            a,
                                                                                            b
                                                                                        ) =>
                                                                                            b[1] -
                                                                                            a[1]
                                                                                    ) // Sort by count descending
                                                                                    .map(
                                                                                        ([
                                                                                            value,
                                                                                            count,
                                                                                        ]) => (
                                                                                            <div
                                                                                                key={
                                                                                                    value
                                                                                                }
                                                                                                className="flex items-center"
                                                                                            >
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    id={`${sublevel}-${value}`}
                                                                                                    checked={selectedFilters[
                                                                                                        sublevel
                                                                                                    ]?.includes(
                                                                                                        value
                                                                                                    )}
                                                                                                    onChange={() =>
                                                                                                        handleFilterChange(
                                                                                                            sublevel,
                                                                                                            value
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                                <label
                                                                                                    htmlFor={`${sublevel}-${value}`}
                                                                                                    className="ml-2 text-sm"
                                                                                                >
                                                                                                    {
                                                                                                        value
                                                                                                    }{" "}
                                                                                                    (
                                                                                                    {
                                                                                                        count
                                                                                                    }

                                                                                                    )
                                                                                                </label>
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                )
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                        </div>
                        <div>{renderGeneralFilters()}</div>
                    </div>
                </div>
            </div>
        </IntlProvider>
    );
};

export default Products;
