import React, { useState } from "react";

function RecurringEventPopup({ onConfirm, onCancel }) {
    const [selectedOption, setSelectedOption] = useState("");

    const handleConfirm = () => {
        if (!selectedOption) {
            alert("Please select an option before proceeding.");
            return;
        }
        onConfirm(selectedOption);
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center text-black text-left z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-80">
                <h2 className="text-lg font-semibold mb-4">
                    Edit recurring event
                </h2>

                <div className="mb-4 mt-10">
                    <div className="space-y-2">
                        <label className="flex items-center space-x-2">
                            <input
                                type="radio"
                                name="recurring-option"
                                value="this-event"
                                checked={selectedOption === "this-event"}
                                onChange={(e) =>
                                    setSelectedOption(e.target.value)
                                }
                                className="form-radio text-blue-500"
                            />
                            <span>This event</span>
                        </label>
                        <label className="flex items-center space-x-2">
                            <input
                                type="radio"
                                name="recurring-option"
                                value="this-and-following"
                                checked={
                                    selectedOption === "this-and-following"
                                }
                                onChange={(e) =>
                                    setSelectedOption(e.target.value)
                                }
                                className="form-radio text-blue-500"
                            />
                            <span>This and following events</span>
                        </label>
                    </div>
                </div>

                <div className="flex justify-end space-x-2 mt-10">
                    <button
                        onClick={onCancel}
                        className="px-4 py-2 white text-black rounded-full hover:bg-gray-400"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleConfirm}
                        className="px-4 py-2 bg-black text-white rounded-full hover:bg-blue-600"
                    >
                        OK
                    </button>
                </div>
            </div>
        </div>
    );
}

export default RecurringEventPopup;
