import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom"; // Make sure you import useNavigate
import { FormattedMessage } from "react-intl";
import ProductModal from "../ModalComponents/ProductModal";

const ProductSearch = ({ clients }) => {
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredClients, setFilteredClients] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [showModal, setShowModal] = useState(false); // Track modal visibility
    const [selectedProduct, setSelectedProduct] = useState(null); // Track the selected product
    // const nav = useNavigate(); // Use the navigate function from react-router-dom

    useEffect(() => {
        if (searchTerm === "") {
            setFilteredClients([]);
        } else {
            const filtered = clients.filter(
                (client) =>
                    client.short_label
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase()) ||
                    client.product_id.toString().includes(searchTerm) // Convert product_id to string before searching
            );
            setFilteredClients(filtered);
        }
    }, [searchTerm, clients]);

    const handleClientClick = (client) => {
        // Set the selected product and show the modal
        setSelectedProduct(client);
        setShowModal(true);
        setShowSuggestions(false); // Hide suggestions on selection
    };

    return (
        <div className="relative flex flex-col md:flex-row items-center w-full">
            <FormattedMessage
                id="home.SearchClients"
                defaultMessage="Rechercher un produit ..."
            >
                {(placeholder) => (
                    <input
                        type="text"
                        className={`mt-1 block w-full px-3 py-2 bg-white border border-slate-300 rounded-full placeholder-slate-400 focus:outline-none focus:border-sky-500 focus:ring-1 focus:ring-black`}
                        style={{
                            minWidth: 350,
                        }}
                        placeholder={placeholder}
                        value={searchTerm}
                        onChange={(e) => {
                            setSearchTerm(e.target.value);
                            setShowSuggestions(true);
                        }}
                        onBlur={() => setShowSuggestions(false)} // Hide suggestions on blur
                        onFocus={() => setShowSuggestions(true)} // Show suggestions on focus
                    />
                )}
            </FormattedMessage>

            {/* Show suggestions if any clients match the search term */}
            {showSuggestions && filteredClients.length > 0 && (
                <div className="absolute top-full w-full bg-white border border-slate-300 rounded-lg shadow-lg max-h-60 overflow-y-auto mt-2 z-10 ">
                    {filteredClients.map((client) => (
                        <div
                            key={client.product_id}
                            onMouseDown={(e) => {
                                e.preventDefault(); // Prevent any unwanted behavior
                                handleClientClick(client);
                            }}
                            className="cursor-pointer px-3 py-2 hover:bg-slate-100 w-full"
                        >
                            <div className="flex flex-row items-center border-b border-gray-100">
                                <div className="w-3/4">
                                    {client.short_label}
                                </div>
                                <div className="text-gray-500 text-sm ml-2 w-1/4">
                                    {client.product_id}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {/* Modal */}
            {showModal && selectedProduct && (
                <ProductModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    product={selectedProduct}
                />
            )}
        </div>
    );
};

export default ProductSearch;
