import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom"; // Import useLocation and useNavigate hooks
import FastAPIClient from "../../client";
import config from "../../config";
import Channel from "../../components/Chat/Channel";
import ClientSearchChat from "../../components/Search/ClientSearchChat";
import ClientChatPreview from "../../components/Chat/ClientChatPreview";
import { IntlProvider } from "react-intl";

import messages_en from "./translations/en.json";
import messages_fr from "./translations/fr.json";
import Throbber from "../../style/Throbber";

const client = new FastAPIClient(config);

const messages = {
    en: messages_en,
    fr: messages_fr,
};

const Chat = () => {
    const [clients, setClients] = useState([]);
    const [orderedClients, setOrderedClients] = useState([]);
    const [username, setUsername] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    const [notifications, setNotifications] = useState([]);

    const [messageList, setMessageList] = useState([]);
    const [messageLoading, setMessageLoading] = useState(true);
    const [shownMessages, setShownMessages] = useState([]);

    const [isOnline, setIsOnline] = useState(navigator.onLine);

    const navigate = useNavigate();
    const location = useLocation();

    const getUserName = () => {
        client.fetchUser().then((data) => {
            setUsername(data);
        });
    };

    const getNotifications = (userId) => {
        client.getNotifications(userId).then((data) => {
            setNotifications(data?.notifications);
        });
    };

    useEffect(() => {
        const handleOnlineStatus = () => {
            if (navigator.onLine) {
                fetchUserClientsOnline();
                setIsOnline(true);
            } else {
                fetchUserClientsOffline();
                setIsOnline(false);
            }
            console.log("clients", clients);
        };

        handleOnlineStatus();

        window.addEventListener("online", handleOnlineStatus);
        window.addEventListener("offline", handleOnlineStatus);

        return () => {
            window.removeEventListener("online", handleOnlineStatus);
            window.removeEventListener("offline", handleOnlineStatus);
        };
    }, [username]);

    const fetchUserClientsOffline = () => {
        if (username.user_id != null) {
            client.getUserClients(username.user_id, null).then((data) => {
                const sortedResults = data.sort((a, b) => b.alert - a.alert);
                setClients(sortedResults);
            });
        } else {
            console.log("user_id is null or undefined, skipping API call.");
        }
    };

    const fetchUserClientsOnline = () => {
        if (username.user_id != null) {
            client.getUserClientsOnline(username.user_id, null).then((data) => {
                const sortedResults = data.sort((a, b) => b.alert - a.alert);
                setClients(sortedResults);
            });
        } else {
            console.log("user_id is null or undefined, skipping API call.");
        }
    };

    useEffect(() => {
        getUserName();
    }, []);

    useEffect(() => {
        if (username.user_id && isOnline) {
            getNotifications(username.user_id);
        }
    }, [username.user_id, selectedClient]);

    useEffect(() => {
        const urlParams = new URLSearchParams(location.search);
        const clientIdFromUrl = urlParams.get("client_id");
        if (clientIdFromUrl) {
            setSelectedClient(clientIdFromUrl);
        }
    }, [location.search]);

    const handleClientClick = (clientId) => {
        setSelectedClient(clientId);
        client.updateNotifications(username.user_id, clientId);

        const urlParams = new URLSearchParams(location.search);
        urlParams.set("client_id", clientId);
        navigate(`${location.pathname}?${urlParams.toString()}`, {
            replace: true,
        });
    };

    // Function to reset the selected client
    const resetSelectedClient = () => {
        setSelectedClient(null);
        const urlParams = new URLSearchParams(location.search);
        urlParams.delete("client_id");
        navigate(`${location.pathname}?${urlParams.toString()}`, {
            replace: true,
        });
    };

    // order messages

    const getAllMessages = async () => {
        const response = await client.getMessages();
        setMessageList(response);
    };

    const sortMessages = (messageList, clients) => {
        if (!messageList) return;

        // Sort messages by most recent date
        const sortedMessages = [...messageList].sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
        console.log("sorted by date", sortedMessages);
        // Get unique client IDs with messages
        const clientIdsWithMessages = [
            ...new Set(sortedMessages.map((mess) => mess.client_id)),
        ];

        // Separate clients into two groups: those with messages and those without
        const clientsWithMessages = clientIdsWithMessages
            .map((clientId) =>
                clients.find((client) => client.client_id === clientId)
            )
            .filter(Boolean);

        const clientsWithoutMessages = clients.filter(
            (client) => !clientIdsWithMessages.includes(client.client_id)
        );

        // Combine groups, preserving order of messages first, then alphabetical
        const orderedClients = [
            ...clientsWithMessages,
            ...clientsWithoutMessages.sort((a, b) =>
                a.client_name.localeCompare(b.client_name)
            ),
        ];

        setOrderedClients(orderedClients);
        if (messageLoading && orderedClients.length > 0) {
            setMessageLoading(false);
        }
    };

    useEffect(() => {
        getAllMessages();
        console.log(messageLoading);
    }, [shownMessages, clients, username]);

    useEffect(() => {
        console.log(messageList);
        sortMessages(messageList, clients);
        console.log("ordered", orderedClients);
        console.log("shown messages", shownMessages);
        console.log("isLoading", messageLoading);
    }, [messageList, shownMessages]);

    return (
        <div className="flex h-screen">
            <IntlProvider locale={"fr"} messages={messages["fr"]}>
                <div
                    className={`md:block ${
                        selectedClient ? "hidden" : "block"
                    } md:w-1/4 w-full border-r border-gray-300 p-4 overflow-y-auto`}
                >
                    <ClientSearchChat
                        clients={clients}
                        setSelectedClient={setSelectedClient}
                    />
                    {messageLoading && <Throbber />}
                    {!messageLoading && (
                        <ul className="list-none p-0">
                            {orderedClients?.map((client) => (
                                <ClientChatPreview
                                    key={client.client_id}
                                    client={client}
                                    handleClientClick={handleClientClick}
                                    notifications={notifications}
                                    selectedClient={selectedClient}
                                />
                            ))}
                        </ul>
                    )}
                </div>

                <div
                    className={`flex-1 p-4 ${
                        !selectedClient ? "hidden" : "block"
                    }`}
                >
                    {selectedClient ? (
                        <Channel
                            client_id={selectedClient}
                            currentUser={username.user_id}
                            clientName={
                                clients.find(
                                    (client) =>
                                        client.client_id === selectedClient
                                )?.client_name
                            }
                            setSelectedClient={resetSelectedClient} // Pass reset function
                            isOnline={isOnline}
                            messages={shownMessages}
                            setMessages={setShownMessages}
                        />
                    ) : (
                        <div>Veuillez sélectionner une conversation</div>
                    )}
                </div>
            </IntlProvider>
        </div>
    );
};

export default Chat;
