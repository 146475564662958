/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid"; // Import UUID generator

import FastAPIClient from "../../../client";
import config from "../../../config";
const client = new FastAPIClient(config);

import { FormattedMessage, IntlProvider } from "react-intl";

const ReminderModal = ({ reminderData, setNewEventData }) => {
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;

        setNewEventData((prevData) => ({ ...prevData, [name]: value }));
    };

    return (
        <div className="bg-white w-full mt-5">
            {error && (
                <div className="fixed top-4 left-1/2 transform -translate-x-1/2 bg-red-500 text-white px-4 py-2 rounded">
                    {error}
                </div>
            )}

            {success && (
                <div className="fixed top-4 left-1/2 transform -translate-x-1/2 bg-green-500 text-white px-4 py-2 rounded">
                    {success}
                </div>
            )}

            <form className="text-left">
                <div className="flex items-center mb-2">
                    <label className="w-1/3 pr-2">Date:</label>
                    <input
                        type="date"
                        name="start_date"
                        value={reminderData.start_date || ""}
                        onChange={handleChange}
                        placeholder="Select start time"
                        className="border rounded-full w-full p-2"
                    />
                </div>
                <div className="flex items-center mb-2">
                    <label className="w-1/3 pr-2">Objet:</label>
                    <input
                        type="text"
                        name="title"
                        value={reminderData.title}
                        onChange={handleChange}
                        className="border rounded-full w-full p-2"
                        placeholder="Entrez la raison"
                    />
                </div>
                <div className="flex items-center mb-2">
                    <label className="w-1/3 pr-2">Commentaires:</label>
                    <input
                        type="text"
                        name="comment"
                        value={reminderData.comment}
                        onChange={handleChange}
                        className="border rounded-full w-full p-2"
                        placeholder="Entrez un commentaire"
                    />
                </div>
            </form>
        </div>
    );
};

export default ReminderModal;
