
export const prefix = `/api/v1`
// need to cache response data api
export const CACHE_RESPONSE_API = {
  ME: prefix + '/auth/me',
  CLIENTS: prefix + '/clients/full_details/',
  MEETINGS: prefix + '/meetings/',
  PRODUCT: prefix + '/product/',
  MEETING_TYPES: prefix +'/meetings/meeting_types/',
  LAYOUT: prefix + '/layout/',
  REPORTS_LAYOUT: prefix + '/reports/layout/',
  USER: prefix + '/user/',
}

/**
 * cache response map data
 * @key {string} api url
 * @storeName {string} indexDB ObjectStore Name
 * @patterns  {RegExp} cache response url pattern
 */
export const CACHE_RESPONSE_DATA = {
  [CACHE_RESPONSE_API.ME]: {
    storeName: 'me',
    patterns: new RegExp(`^${prefix}/auth/me(?:\\?.*)?$`),
  },
  [CACHE_RESPONSE_API.CLIENTS]: {
    storeName: 'clients',
    patterns: new RegExp(`^${prefix}/clients/full_details/\\?(?=.*user_id=)(?:[^?#]*)$`),
  },
  [CACHE_RESPONSE_API.MEETINGS]: {
    storeName: 'meetings',
    patterns: new RegExp(`^${prefix}/meetings/(?:\\d+|\\?.*)?$`),
  },
  [CACHE_RESPONSE_API.PRODUCT]: {
    storeName: 'product',
    patterns: new RegExp(`^${prefix}/product/(?:\\d+|\\?.*)?$`),
  },
  [CACHE_RESPONSE_API.MEETING_TYPES]: {
    storeName: 'meeting_types',
    patterns: new RegExp(`^${prefix}/meetings/meeting_types/(?:\\d+|\\?.*)?$`),
  },
  [CACHE_RESPONSE_API.LAYOUT]: {
    storeName: 'layout',
  },
  [CACHE_RESPONSE_API.REPORTS_LAYOUT]: {
    storeName: 'reports_layout',
  },

  [CACHE_RESPONSE_API.USER]: {
    storeName: 'user',
  },
}

//  offline operate api
export const OFFINE_OPERATE_API = {
  CREATE_MEETING: {
    path: prefix + '/meetings/',
    method: 'POST',
  },
  RECOMMENDATION: {
    path: prefix + '/recommendation/',
    method: 'PUT',
  },
  REPORTS: {
    path: prefix + '/reports/',
    method: 'POST',
  },
  DELETE_MEETING: {
    path: prefix + '/meetings/',
    method: 'DELETE',
  },
  UPDATE_MEETING: {
    path: prefix + '/meetings/',
    method: 'PUT',
  },
  CREATE_REMINDER: {
    path: prefix + '/reminder/',
    method: 'POST',
  },
}

/**
 * offline operate data
 * @key {string} api url
 * @storeName {string} indexDB ObjectStore Name
 * @patterns  {RegExp} cache response url pattern
 * @method {string} request method
 * @messageType {string} message type
 */
export const OFFINE_OPERATE_DATA = {
  [JSON.stringify(OFFINE_OPERATE_API.CREATE_MEETING)]: {
    method: 'POST',
    storeName: 'create_meeting',
    messageType: 'update_meeting_list',
    keyPath: 'id'
  },
  [JSON.stringify(OFFINE_OPERATE_API.RECOMMENDATION)]: {
    storeName: 'edit_recommendation',
    method: 'PUT',
    messageType: 'update_recommendation',
    patterns: new RegExp(`^${prefix}/recommendation/(?:\\d+|\\?.*)?$`),
    keyPath: 'url',
  },
  [JSON.stringify(OFFINE_OPERATE_API.REPORTS)]: {
    storeName: 'edit_reports',
    method: 'POST',
    messageType: 'update_reports',
    keyPath: 'id',
  },
  [JSON.stringify(OFFINE_OPERATE_API.DELETE_MEETING)]: {
    method: 'DELETE',
    storeName: 'delete_meeting',
    messageType: 'delete_meeting',
    patterns: new RegExp(`^${prefix}/meetings/(?:\\d+|\\?.*)?$`),
    keyPath: 'url',
  },
  [JSON.stringify(OFFINE_OPERATE_API.UPDATE_MEETING)]: {
    method: 'PUT',
    storeName: 'update_meeting',
    messageType: 'update_meeting',
    patterns: new RegExp(`^${prefix}/meetings/(?:\\d+|\\?.*)?$`),
    keyPath: 'url',
  },
  [JSON.stringify(OFFINE_OPERATE_API.CREATE_REMINDER)]: {
    method: 'POST',
    storeName: 'create_reminder',
    messageType: 'create_new_reminder',
    keyPath: 'id'
  },

}
