import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Plugins } from "@capacitor/core";
import { IonButton } from "@ionic/react";

const { Browser } = Plugins;

const LoginButton = () => {
    const { loginWithRedirect } = useAuth0();

    const login = async () => {
        await loginWithRedirect({
            async openUrl(url) {
                console.log("Redirecting to: ", url);
                // Redirect using Capacitor's Browser plugin
                await Browser.open({
                    url,
                    windowName: "_self",
                });
            },
        });
    };

    return <IonButton onClick={login}>Log in</IonButton>;
};

export default LoginButton;
